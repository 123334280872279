import { useEffect, useState } from "react";
import { withSuspense } from "hoc";

import Card from "components/ui/Card";

import NotImplemented from "components/system/NotImplemented";
import { useToolbar } from "hooks";
import TasksTable from "./pages/Tasks/parts/TasksTable";
import OnlineEmployees from "./pages/Employee/parts/EmployeeTable";
import TeamsTable from "./pages/Teams/parts/TeamTable";
import usePermissions, { RESOURCE, PERMISSION } from "hooks/usePermissions";

const TasksPage = () => {
	const { crumbsBuilder } = useToolbar();
	const [taskControls, setTaskControls] = useState({});

	const { hasPermission } = usePermissions();
	const permissionsMap = {
		tasks: {
			Create: hasPermission(RESOURCE.TaskItem, PERMISSION.Create),
			View: hasPermission(RESOURCE.TaskItem, PERMISSION.View),
			Update: hasPermission(RESOURCE.TaskItem, PERMISSION.Update),
			Delete: hasPermission(RESOURCE.TaskItem, PERMISSION.Delete),
			Export: hasPermission(RESOURCE.TaskItem, PERMISSION.Export),
			Allocate: hasPermission(RESOURCE.TaskItem, PERMISSION.Allocate),
		},
		employees: {
			Create: hasPermission(RESOURCE.Employee, PERMISSION.Create),
			View: hasPermission(RESOURCE.Employee, PERMISSION.View),
			Update: hasPermission(RESOURCE.Employee, PERMISSION.Update),
			Delete: hasPermission(RESOURCE.Employee, PERMISSION.Delete),
			Export: hasPermission(RESOURCE.Employee, PERMISSION.Export),
		},
		teams: {
			Create: hasPermission(RESOURCE.Team, PERMISSION.Create),
			View: hasPermission(RESOURCE.Team, PERMISSION.View),
			Update: hasPermission(RESOURCE.Team, PERMISSION.Update),
			Delete: hasPermission(RESOURCE.Team, PERMISSION.Delete),
			Export: hasPermission(RESOURCE.Team, PERMISSION.Export),
		},
	};

	useEffect(() => {
		crumbsBuilder.addCurrent("tasks");
		crumbsBuilder.build();
	}, []);

	return (
		<div className="grid grid-cols-1 gap-2">
			<TasksTable
				permissions={permissionsMap.tasks}
				onControlUpdate={(controls) => {
					setTaskControls(controls);
				}}
			/>

			<OnlineEmployees permissions={permissionsMap.employees} />
			<TeamsTable
				permissions={permissionsMap.teams}
				controls={taskControls}
			/>
		</div>
	);
};

export default withSuspense(TasksPage);
