import {
	forwardRef,
	useEffect,
	useState,
	useMemo,
	useCallback,
	useRef,
} from "react";
import { MultiSelect } from "react-multi-select-component";
import { useTranslations } from "hooks";

const MultipleSelect = forwardRef(
	(
		{
			name,
			options,
			value = null,
			onSelectOption = () => {},
			hasSelectAll = true,
			disableOnAll = true,
			allOptionValue = 1,
			valueKey = "id",
			labelKey = "name",
			defaultValue = null,
			disabled = false,
			required = false,
			hasAllOption = true,
			noAllAtSubmit = false,
			valueIsObject = null,
			containerClassName = "",
			placeholder = null,
			hideLabel = false,
			label = null,
			labelClassName = null,
		},
		ref
	) => {
		const { translate } = useTranslations();
		const [optionValues, setOptionValues] = useState(options ?? []);
		const [inputInvalid, setInputInvalid] = useState(false);
		const inputRef = useRef(null);

		const values = defaultValue ?? value;

		const valuesAreOnlyId = useMemo(
			() =>
				valueIsObject !== true &&
				(Array.isArray(value)
					? value.every((item) => typeof item === "number")
					: true),
			[value, valueIsObject]
		);

		const getNameFromOptions = useCallback(
			(val) => options.find((item) => item.value === val)?.label,
			[options]
		);

		const formatValues = useCallback(
			(l_values) => {
				if (!l_values || !options.length) return [];

				const selectedValues = l_values.filter((item) =>
					valuesAreOnlyId
						? options.some((opt) => opt.value === item)
						: options.some(
								(opt) => opt[valueKey] === item[valueKey]
						  )
				);

				if (
					hasSelectAll &&
					selectedValues.some((item) => item === allOptionValue)
				) {
					return options.filter(
						(item) => item.value !== allOptionValue
					);
				}

				return selectedValues.map((item) =>
					valuesAreOnlyId
						? { value: item, label: getNameFromOptions(item) }
						: { value: item[valueKey], label: item[labelKey] }
				);
			},
			[
				options,
				valuesAreOnlyId,
				getNameFromOptions,
				valueKey,
				labelKey,
				allOptionValue,
				hasSelectAll,
			]
		);

		const [selectedOptions, setSelectedOptions] = useState(
			formatValues(values)
		);

		useEffect(() => {
			if (valuesAreOnlyId) {
				const selected = selectedOptions.map((item) => item.value);
				onSelectOption(selected);
			} else {
				onSelectOption(selectedOptions);
			}
		}, [selectedOptions]);

		useEffect(() => {
			setOptionValues(options);

			const values = defaultValue ?? value;

			if (values) {
				setSelectedOptions(formatValues(values));
			}
		}, [options, defaultValue, value]);

		function getSelectedOptionsValues() {
			//if all items are selected return only the allOptionValue
			if (
				!noAllAtSubmit &&
				selectedOptions.length ===
					optionValues.length - (hasAllOption ? 1 : 0)
			) {
				return [allOptionValue];
			} else
				return selectedOptions
					.filter((item) => item.value !== allOptionValue)
					.map((i) => i.value);
		}

		const customValueRenderer = (selected, _options) => {
			if (selected.length === _options.length) {
				return translate("allItemsAreSelected");
			}

			let labels = selected
				.map(({ label }) => label + ", ")
				.join("")
				.slice(0, -2);
			labels =
				labels.length > 35 ? labels.substring(0, 35) + "..." : labels;
			return selected.length
				? labels
				: placeholder || `${translate("selectAnItem")}...`;
		};

		const checkValidity = () => {
			if (required && selectedOptions.length === 0) {
				setInputInvalid(true);
			} else {
				setInputInvalid(false);
			}
		};

		useEffect(() => {
			checkValidity();
		}, [selectedOptions]);

		return (
			<div
				className={`relative w-full rounded-lg border  ${
					inputInvalid ? " border-red-500" : " border-gray-300"
				} ${containerClassName}`}
			>
				{!hideLabel && label && (
					<label
						className={`block mb-1 text-xs font-medium text-gray-700 ${labelClassName}`}
					>
						{translate(label || "", true)}
					</label>
				)}
				<input
					ref={inputRef}
					type="text"
					className="sr-only"
					required={required}
					name={`${name}`}
					onChange={() => {}}
					value={getSelectedOptionsValues()}
				/>
				{/* //TODO: WE need some internaization within here */}
				<MultiSelect
					disabled={disabled}
					ref={ref}
					overrideStrings={{
						selectSomeItems: translate("selectSomeItems"),
						allItemsAreSelected: translate("allItemsAreSelected"),
						selectAll: translate("selectAll"),
						search: translate("search"),
					}}
					valueRenderer={customValueRenderer}
					ArrowRenderer={() => (
						<i className="ri-arrow-down-s-line"></i>
					)}
					className="block rounded-lg  text-sm font-medium text-gray-900"
					options={
						optionValues
							? hasAllOption
								? optionValues?.filter(
										(item) => item.value !== allOptionValue
								  )
								: optionValues
							: null
					}
					onChange={(selected) => {
						setSelectedOptions(selected);
					}}
					value={selectedOptions || null}
					hasSelectAll={hasSelectAll}
				/>
			</div>
		);
	}
);
export default MultipleSelect;
