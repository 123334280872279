import Card from "components/ui/Card";
import QRCode from "react-qr-code";

import configMap from "configmap.json";
import tenant from "config/tenant";

const PorterConfig = () => {
	const envConfig = {
		...configMap,
		tenant: tenant.id,
	};

	return (
		<Card
			className="mt-2"
			collapsible
			header={"Porter Config"}
			defaultOpen={true}
		>
			<div className="text-lg">
				Steps to configure Porter for your environment:
			</div>
			<ul className="mt-4 pl-8 list-disc">
				<li>Open Porter app, press the ENV footer in Login screen</li>
				<li>Press configure different enviroment</li>
				<li>Scan the below QR code</li>
			</ul>
			<div className="p-8">
				<QRCode size={256} value={JSON.stringify(envConfig)} />
			</div>
		</Card>
	);
};

export default PorterConfig;
