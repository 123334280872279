import { useEffect, useState } from "react";
import { FormEntry } from "components/form";
import { ReSelect } from "components/ui/Input";
import { runAction } from "modules/utils";
import { useTranslations } from "hooks";
import TeamService from "modules/persons/pages/Users/pages/TeamsAndDepartments/Teams/services";

const TeamInput = ({ selectedValue, onChange, disabled = false }) => {
	const [teamOptions, setTeamOptions] = useState([]);
	const [teamRolePairs, setTeamRolePairs] = useState([]);
	const [teamRoles, setTeamRoles] = useState([]);
	const { translate } = useTranslations();
	const service = new TeamService();

	useEffect(() => {
		service.getOptionsList(service.queryString).then((res) => {
			setTeamOptions(res.data);
		});
	}, []);

	const getGeneralContents = async () => {
		await runAction("tenants", "getEnum", "TeamRole")
			.then((enumValues) => {
				const l_teamRoles = [];
				Object.keys(enumValues).forEach((key) => {
					l_teamRoles.push({
						label: key,
						value: enumValues[key],
					});
				});

				setTeamRoles(l_teamRoles);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	useEffect(() => {
		if (selectedValue && selectedValue.length > 0) {
			const initialPairs = selectedValue.map((item) => ({
				id: item.id,
				roleId: item.role.id,
			}));
			setTeamRolePairs(initialPairs);
		} else if (teamOptions.length > 0 && teamRoles.length > 0) {
			// To set a default team:
			// setTeamRolePairs([
			// 	{ id: teamOptions[0].value, roleId: teamRoles[0].value },
			// ]);
		}
	}, [teamOptions, teamRoles, selectedValue]);

	const addPair = () => {
		setTeamRolePairs([
			...teamRolePairs,
			{
				id: teamOptions ? teamOptions[0]?.value : null,
				roleId: teamRoles ? teamRoles[1]?.value : null, //By default add worker
			},
		]);
	};

	const removePair = (index) => {
		setTeamRolePairs(teamRolePairs.filter((_, i) => i !== index));
	};

	const handleTeamChange = (value, index) => {
		const newPairs = [...teamRolePairs];
		newPairs[index].id = value;
		console.log("newPairs", newPairs);
		setTeamRolePairs(newPairs);
	};

	const handleRoleChange = (value, index) => {
		const newPairs = [...teamRolePairs];
		newPairs[index].roleId = value;
		setTeamRolePairs(newPairs);
	};

	useEffect(() => {
		getGeneralContents();
	}, []);

	return (
		<FormEntry
			label="teams"
			helpText={
				<div className="mt-2 flex justify-end mr-2">
					<button
						disabled={disabled}
						type="button"
						className="text-right text-black text-sm font-bold leading-normal"
						onClick={addPair}
					>
						<i className="ri-add-line"></i>
						{translate("addTeam")}
					</button>
				</div>
			}
		>
			<input
				type="hidden"
				name="teams"
				value={JSON.stringify(teamRolePairs)}
			/>
			{teamRolePairs.map((pair, index) => (
				<tr key={index}>
					<td className="w-1/2">
						<ReSelect
							disabled={disabled}
							value={pair?.id || teamOptions[0]?.value}
							onChange={(value) => handleTeamChange(value, index)}
							options={teamOptions}
						/>
					</td>
					<td className="w-1/2">
						<ReSelect
							disabled={disabled}
							value={pair?.roleId || teamRoles[0]?.value}
							onChange={(value) => handleRoleChange(value, index)}
							options={teamRoles}
						/>
					</td>
					<td>
						{teamRolePairs.length > 0 && (
							<button
								type="button"
								className="px-1"
								onClick={() => removePair(index)}
							>
								<i className="ri-delete-bin-line"></i>
							</button>
						)}
					</td>
				</tr>
			))}
			{teamRolePairs.length === 0 && (
				<tr>
					<td className="pt-2">--</td>
				</tr>
			)}
		</FormEntry>
	);
};
export default TeamInput;
