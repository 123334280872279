import React, { useState, useEffect, useRef } from "react";
import Card from "components/ui/Card";

import { FormEntry, Form, InternalFormDivider } from "components/form";
import { useTranslations } from "hooks";

import { useCrud } from "hooks";
import {
	CheckBox,
	Input,
	TextArea,
	Select,
	ReSelect,
	Button,
} from "components/ui/Input";
import TenantService from "../../services";

const DispatcherSettingsView = ({ permissions }) => {
	const [settings, setSettings] = useState(null);

	const myForm = useRef(null);
	const { translate } = useTranslations();
	const service = new TenantService();

	const [isLoading, setIsLoading] = useState(false);

	const { create, getOne } = useCrud({
		create: (data) => service.putDispatcherSettings(data),
		getOne: (id) => service.getDispatcherSettings(),
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(myForm.current);
		const formDataObject = Object.fromEntries(formData.entries());
		setIsLoading(true);
		await postSettings(formDataObject, (res) => {
			setIsLoading(false);
			getSettings(false);
		});
	};

	const postSettings = async (data, onSuccess = () => {}) => {
		if (data) {
			return await create(data, {
				creationSuccessMessage:
					"tenantDispatcherSettingsUpdatedSuccessfully",
			}).then((res) => {
				onSuccess(res);
			});
		}
		return null;
	};

	useEffect(() => {
		getSettings();
	}, []);

	const getSettings = async () => {
		setIsLoading(true);
		const res = await getOne();
		setSettings(res);
		setIsLoading(false);
	};

	return (
		<Card
			isLoading={isLoading}
			className="mt-2"
			collapsible
			header={"dispatcherSettings"}
			defaultOpen={true}
		>
			<Form ref={myForm} onSubmit={(e) => handleSubmit(e)}>
				<FormEntry label={"enabled"}>
					<CheckBox
						name="enabled"
						label="enabled"
						selected={settings?.enabled}
					/>
				</FormEntry>
				<FormEntry label={"dispatchTaskToTheSameGender"}>
					<CheckBox
						name="dispatchTaskToTheSameGender"
						label="dispatchTaskToTheSameGender"
						selected={settings?.dispatchTaskToTheSameGender}
					/>
				</FormEntry>
				<FormEntry required label={"dispatcherRunIntervalInSeconds"}>
					<Input
						containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
						placeholder={"dispatcherRunIntervalInSeconds"}
						type="number"
						min={0}
						required={true}
						name="dispatcherRunIntervalInSeconds"
						defaultValue={settings?.dispatcherRunIntervalInSeconds}
						sideLabel={"seconds"}
					/>
				</FormEntry>
				<FormEntry label={"cancelTaskAllocationWhenEmployeeOnBreak"}>
					<CheckBox
						name="cancelTaskAllocationWhenEmployeeOnBreak"
						label="cancelTaskAllocationWhenEmployeeOnBreak"
						selected={
							settings?.cancelTaskAllocationWhenEmployeeOnBreak
						}
					/>
				</FormEntry>
				<FormEntry label={"cancelTaskAllocationWhenEmployeeLogsOut"}>
					<CheckBox
						name="cancelTaskAllocationWhenEmployeeLogsOut"
						label="cancelTaskAllocationWhenEmployeeLogsOut"
						selected={
							settings?.cancelTaskAllocationWhenEmployeeLogsOut
						}
					/>
				</FormEntry>
				<FormEntry
					required
					label={"stillInTransitWhenArrivalTimePassedXMinutes"}
				>
					<Input
						containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
						placeholder={
							"stillInTransitWhenArrivalTimePassedXMinutes"
						}
						type="number"
						min={0}
						required={true}
						name="stillInTransitWhenArrivalTimePassedXMinutes"
						defaultValue={
							settings?.stillInTransitWhenArrivalTimePassedXMinutes
						}
						sideLabel={"mins"}
					/>
				</FormEntry>
				<FormEntry
					required
					label={"completeTaskIfArrivalTimeHasPassedWithXMinutes"}
				>
					<Input
						containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
						placeholder={
							"completeTaskIfArrivalTimeHasPassedWithXMinutes"
						}
						type="number"
						min={0}
						required={true}
						name="completeTaskIfArrivalTimeHasPassedWithXMinutes"
						defaultValue={
							settings?.completeTaskIfArrivalTimeHasPassedWithXMinutes
						}
						sideLabel={"mins"}
					/>
				</FormEntry>
				<FormEntry
					required
					label={"assignNewTaskToEmployeeAfterXMinutesWithoutTask"}
				>
					<Input
						containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
						placeholder={
							"assignNewTaskToEmployeeAfterXMinutesWithoutTask"
						}
						type="number"
						min={0}
						required={true}
						name="assignNewTaskToEmployeeAfterXMinutesWithoutTask"
						defaultValue={
							settings?.assignNewTaskToEmployeeAfterXMinutesWithoutTask
						}
						sideLabel={"mins"}
					/>
				</FormEntry>
				<FormEntry
					required
					label={
						"askEmployeeIfSecondEmployeeIsStillNeededAfterXMinutes"
					}
				>
					<Input
						containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
						placeholder={
							"askEmployeeIfSecondEmployeeIsStillNeededAfterXMinutes"
						}
						type="number"
						min={0}
						required={true}
						name="askEmployeeIfSecondEmployeeIsStillNeededAfterXMinutes"
						defaultValue={
							settings?.askEmployeeIfSecondEmployeeIsStillNeededAfterXMinutes
						}
						sideLabel={"mins"}
					/>
				</FormEntry>
				<FormEntry label={"enabledLeftOverTask"}>
					<CheckBox
						name="enabledLeftOverTask"
						label="enabledLeftOverTask"
						selected={settings?.enabledLeftOverTask}
					/>
				</FormEntry>
				<FormEntry required label={"leftOverTaskRunIntervalInMinutes"}>
					<Input
						containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
						placeholder={"leftOverTaskRunIntervalInMinutes"}
						type="number"
						min={0}
						required={true}
						name="leftOverTaskRunIntervalInMinutes"
						defaultValue={
							settings?.leftOverTaskRunIntervalInMinutes
						}
						sideLabel={"mins"}
					/>
				</FormEntry>

				{permissions.Update && (
					<tr>
						<td colSpan={2}>
							<div className="text-right p-10 pb-5">
								<Button type="submit" isLoading={isLoading}>
									<i className="ri-save-line mr-2"></i>
									{translate("saveChanges")}
								</Button>
							</div>
						</td>
					</tr>
				)}
			</Form>
		</Card>
	);
};
export default DispatcherSettingsView;
