import React, { useState, useEffect, forwardRef } from "react";
import Card from "components/ui/Card";
import { Table } from "components/ui/Table";
import AdjustStaRuleService from "../services";
import RuleColumns from "../../../models/index";
import { useTranslations, useCrud } from "hooks";
import AdjustStaRuleForm from "../forms/AdjustStaRule.form";
import { CrudOperations } from "hoc";

const Tables = ({ permissions }) => {
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const service = new AdjustStaRuleService();
	const { create, update, getAll, remove } = useCrud(service);

	const { translate } = useTranslations();

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			setIsLoading(true);
			const res = await getAll(l_queryString || queryString);
			setIsLoading(false);
			setData(res);
		} catch (error) {
			console.error("Error on Get All request: ", error);
		}
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: AdjustStaRuleForm,
		componentName: "adjustStaRule",
		permissions: permissions,
	});

	return (
		<Card
			className="mt-2"
			collapsible
			header={`adjustStaRules`}
			defaultOpen={true}
			headerButtonClick={openCreate}
			headerButtonLabel={
				permissions.Create && `+ ${translate("addAdjustStaRule")}`
			}
		>
			<Table
				displayCheckBoxes={false}
				isLoading={isLoading}
				isExportable={false}
				columns={RuleColumns}
				meta={data.meta}
				data={data.data}
				onTableRequestChange={(queryStr) => {
					setQueryString(queryStr);
					fetchAndLoad(queryStr);
				}}
				onRowClick={(row) => permissions.Update && openEdit(row)}
			/>
		</Card>
	);
};
export default Tables;
