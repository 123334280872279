import React, { useState, useEffect, forwardRef } from "react";
import Input from "./Input";
const DateTimeInput = forwardRef(({ name, ...props }, ref) => {
	const [date, setDate] = useState(null);
	const [isoDate, setIsoDate] = useState(props?.value || props?.defaultValue);

	const convertToIsoDate = (date) => {
		const isoDate = new Date(date).toISOString();
		setIsoDate(isoDate);
	};

	useEffect(() => {
		if (props.value || props?.defaultValue) {
			const date = new Date(props.value || props?.defaultValue);
			const timezoneOffset = date.getTimezoneOffset() * 60000;
			const localISOTime = new Date(date - timezoneOffset)
				.toISOString()
				.substring(0, 16);
			setDate(localISOTime);
		}
	}, [props?.value, props?.defaultValue]);

	return (
		<>
			<input type="hidden" name={name} value={isoDate} />
			<Input
				ref={ref}
				{...props}
				defaultValue={date}
				onChange={(event) => convertToIsoDate(event.target.value)}
				type="datetime-local"
			/>
		</>
	);
});

export default DateTimeInput;
