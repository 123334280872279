import React, {
	useRef,
	forwardRef,
	useImperativeHandle,
	useState,
	useMemo,
	useEffect,
	useCallback,
} from "react";
import { createPortal } from "react-dom";

import { Scanner } from "@yudiel/react-qr-scanner";
import { Button } from "components/ui/Input";

const NfcScanner = forwardRef((props, ref) => {
	const [show, setShow] = useState(false);
	const [scanned, setScanned] = useState(null);

	const dataCallback = useRef(null);

	const closeAndReset = () => {
		console.log("Closing");
		setShow(false);
		dataCallback.current = null;
	};

	useImperativeHandle(ref, () => ({
		callAction: async (action, data, callback) => {
			switch (action) {
				case "open":
					setShow(true);
					dataCallback.current = callback;

					break;
				default:
					throw Error(
						`No action named ${action} for portal ${props.id}`
					);
			}
		},
	}));

	const hasNfcSupport = useMemo(() => "NDEFReader" in window, []);

	const onReading = useCallback(
		({ message, serialNumber }) => {
			if (!scanned) {
				for (const record of message.records) {
					const textDecoder = new TextDecoder(record.encoding);
					setScanned(textDecoder.decode(record.data));
				}
			}
		},
		[scanned]
	);

	const startListeningNfc = useCallback(async () => {
		const ndef = new window.NDEFReader();
		try {
			await ndef.scan();

			ndef.onreadingerror = (err) => {
				console.log(
					"Cannot read data from the NFC tag. Try another one?"
				);
				alert("CANNOT READ DATA FROM THIS TAG");
			};

			ndef.onreading = (event) => {
				onReading(event);
			};
		} catch (err) {
			alert(JSON.stringify(err, null, 2));
		}
	}, [onReading]);

	useEffect(() => {
		if (hasNfcSupport) {
			startListeningNfc();
		}
	}, [hasNfcSupport, startListeningNfc]);

	return (
		<>
			{show &&
				createPortal(
					<div
						onClick={() => closeAndReset()}
						className="absolute z-50 top-0 bottom-0 right-0 left-0 bg-slate-800/50 flex justify-center items-center"
					>
						<div
							onClick={(e) => e.stopPropagation()}
							className={`relative flex flex-col h-fit w-3/4 sm:w-2/3 md:w-3/5 lg:w-1/3 rounded-lg bg-white ${
								scanned
									? "py-12"
									: "p-8 aspect-square justify-center items-center"
							}`}
						>
							{!scanned ? (
								<>
									{hasNfcSupport ? (
										<img
											src={require("assets/other/nfc.png")}
											alt="nfc scan"
											className="w-1/2 h-1/2 animate-pulse"
										/>
									) : (
										<>
											<div className="text-center">
												This device does not support
												NFC! Please try scanning from a
												device with NFC support
											</div>
											<Button
												onClick={() => {
													closeAndReset();
												}}
												className="mt-4"
											>
												Close
											</Button>
										</>
									)}
								</>
							) : (
								<>
									<div className="text-xl text-center">
										{scanned}
									</div>
									<Button
										onClick={() => {
											dataCallback.current(scanned);
											closeAndReset();
										}}
										className="mt-4"
									>
										Done
									</Button>
								</>
							)}
						</div>
					</div>,
					document.body
				)}
		</>
	);
});

export default NfcScanner;
