import React, { useState, useEffect, forwardRef } from "react";
import {
	CheckBox,
	Input,
	MultipleSelect,
	ReSelect,
	TextArea,
	MultiAddressSelector,
	Button,
} from "components/ui/Input";
import { useTranslations, useCrud, useAside } from "hooks";
import TaskGroupService from "modules/tasks/pages/Manage/services";
import { getToday } from "utils";

const TableControls = ({ onChange = () => {} }) => {
	const { translate } = useTranslations();

	const taskGroupService = new TaskGroupService();

	const fetchTaskGroups = async () => {
		taskGroupService.getOptionsList().then((res) => {
			setTaskGroupOptions(res.data);
		});
	};

	const today = getToday();
	const [taskGroupOptions, setTaskGroupOptions] = useState([]);
	const [actualFilters, setActualFilters] = useState({
		date: today,
		statuses: [],
		taskGroup: [],
	});

	useEffect(() => {
		onChange(actualFilters);
	}, [actualFilters]);

	useEffect(() => {
		fetchTaskGroups();
	}, []);

	const statusOptions = [
		{
			value: 1,
			label: translate("request"),
		},
		{
			value: 2,
			label: translate("assigned"),
		},
		{
			value: 3,
			label: translate("accepted"),
		},
		{
			value: 4,
			label: translate("inTransit"),
		},
		{
			value: 5,
			label: translate("completed"),
		},
		{
			value: 6,
			label: translate("cancelled"),
		},
		{
			value: 7,
			label: translate("provisional"),
		},
	];

	const onTodayClick = () => {
		setActualFilters((prev) => {
			return {
				...prev,
				date: today,
			};
		});
	};

	const onDateChange = (e) => {
		setActualFilters((prev) => {
			return {
				...prev,
				date: e.target.value,
			};
		});
	};

	const onNextDayClick = () => {
		const nextDay = new Date(actualFilters.date);
		nextDay.setDate(nextDay.getDate() + 1);

		setActualFilters((prev) => {
			return {
				...prev,
				date: nextDay.toISOString().split("T")[0],
			};
		});
	};

	const onPrevDayClick = () => {
		const prevDay = new Date(actualFilters.date);
		prevDay.setDate(prevDay.getDate() - 1);

		setActualFilters((prev) => {
			return {
				...prev,
				date: prevDay.toISOString().split("T")[0],
			};
		});
	};

	return (
		<div className="flex flex-col lg:flex-row pl-2 space-x-2 pb-3 items-center">
			<div className="w-full lg:w-1/4 ">
				<Input
					type="date"
					onChange={(e) => onDateChange(e)}
					value={actualFilters.date}
				/>
			</div>
			<div className="flex flex-row space-x-2 lg:space-x-0 my-2 lg-my-0">
				<div>
					<Button
						onClick={() => onTodayClick()}
						color={"transparent"}
					>
						{translate("today")}
					</Button>
				</div>
				<div>
					<Button
						onClick={() => onPrevDayClick()}
						iconOnly={true}
						color={"transparent"}
					>
						<i className="ri-arrow-left-s-line"></i>
					</Button>
				</div>
				<div>
					<Button
						onClick={() => onNextDayClick()}
						iconOnly={true}
						color={"transparent"}
					>
						<i className="ri-arrow-right-s-line"></i>
					</Button>
				</div>
			</div>

			<div className="w-full lg:w-1/4 ">
				<MultipleSelect
					noAllAtSubmit={true}
					hasAllOption={false}
					placeholder={translate("status")}
					options={statusOptions}
					onSelectOption={(option) => {
						setActualFilters((prev) => {
							return {
								...prev,
								statuses: option,
							};
						});
					}}
				/>
			</div>
			<div className="w-full lg:w-1/4  ">
				<MultipleSelect
					name="taskGroupIds"
					options={taskGroupOptions}
					placeholder={translate("taskGroups")}
					onSelectOption={(option) => {
						setActualFilters((prev) => {
							return {
								...prev,
								taskGroup: option,
							};
						});
					}}
				/>
			</div>
		</div>
	);
};

export default TableControls;
