import NotImplemented from "components/system/NotImplemented";
import CustomFieldTable from "../pages/CustomFields/parts/CustomFieldTable";
import NoReturnSetting from "../pages/DefaultSettings/pages/NoReturnSetting/parts/NoReturnSettingTable";
import TwoPortersSetting from "../pages/DefaultSettings/pages/TwoPorters/parts/TwoPorterSettingTable";
import ProvisionalSetting from "../pages/DefaultSettings/pages/Provisional/parts/ProvisionalSettingTable";
import AccompaniedByNurseSetting from "../pages/DefaultSettings/pages/AccompaniedByNurseSetting/parts/AccompaniedByNurseSettingTable";

import Overview from "../pages/Overview/Overview";
const routes = [
	{
		header: "general",
		key: "General",
		items: [
			// {
			// 	name: "overview",
			// 	key: "Overview",
			// 	hash: "#overview",
			// 	component: Overview,
			// },
			{
				name: "customFields",
				key: "CustomFields",
				hash: "#custom-fields",
				component: CustomFieldTable,
			},
		],
	},
	{
		header: "defaultSettings",
		key: "DefaultSettings",
		items: [
			{
				name: "noReturn",
				key: "NoReturn",
				hash: "#no-return",
				component: NoReturnSetting,
			},
			{
				name: "twoPorters",
				key: "TwoPorters",
				hash: "#two-porters",
				component: TwoPortersSetting,
			},
			{
				name: "provisional",
				key: "Provisional",
				hash: "#provisional",
				component: ProvisionalSetting,
			},
			{
				name: "accompaniedByNurse",
				key: "AccompaniedByNurse",
				hash: "#accompanied-by-nurse",
				component: AccompaniedByNurseSetting,
			},
		],
	},
];
export default routes;
