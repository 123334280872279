import NotImplemented from "components/system/NotImplemented";
import Overview from "../pages/Overview/Overview";
import PermissionGroupsTable from "../pages/PermissionGroups/parts/PermissionGroupsTable";
import ConnectionView from "../pages/ADSetup/parts/ConnectionView";
import ADConfigurationTable from "../pages/ADConfiguration/parts/ADConfigurationTable";
const routes = [
	{
		header: "general",
		key: "General",
		items: [
			// {
			// 	name: "overview",
			// 	key: "Overview",
			// 	hash: "#overview",
			// 	component: Overview,
			// },
			{
				name: "lists",
				key: "Lists",
				hash: "#lists",
				component: NotImplemented,
			},
			{
				name: "permissionGroups",
				key: "PermissionGroup",
				hash: "#permission-groups",
				component: PermissionGroupsTable,
			},
		],
	},
	{
		header: "activeDirectory",
		key: "ActiveDirectory",
		items: [
			{
				name: "setup",
				key: "Setup",
				hash: "#ad-setup",
				component: ConnectionView,
			},
			{
				name: "configuration",
				key: "Configuration",
				hash: "#ad-config",
				component: ADConfigurationTable,
			},
		],
	},
];

export default routes;
