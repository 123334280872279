import {
	forwardRef,
	useEffect,
	useState,
	useRef,
	useImperativeHandle,
	Suspense,
} from "react";
import { useCrud } from "hooks";
import { Each } from "utils/Each";
import { FormEntry, Form } from "components/form";
import { Loading } from "components/ui/Interactive";
import InputField from "modules/tasks/components/InputField";
import TaskTemplateService from "modules/tasks/pages/Manage/pages/TaskTemplates//pages/TaskTemplates/services";

const TaskForm = forwardRef((props, ref) => {
	let service = props?.service;
	if (props?.data?.getServiceName) {
		service = {
			...props?.service,
			getOne: props?.service[props?.data?.getServiceName],
		};
	}

	const { getOne } = useCrud(service);

	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);

	const initiateData = () => {
		if (props?.data?.id) {
			getOne(props.data.id).then((res) => {
				if (res.fields) setFields(res.fields);
				setLoading(false);
			});
		} else {
			if (props?.data.taskGroupId) {
				const taskTemplateService = new TaskTemplateService();
				taskTemplateService.setParent(props?.data.taskGroupId);

				taskTemplateService
					.getOne(props?.data.taskTemplateId)
					.then((res) => {
						if (res.data.fields) setFields(res.data.fields);
						setLoading(false);
					});
			}
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
	};

	useImperativeHandle(ref, () => ({
		getData,
		clear: resetData,
	}));

	const [fields, setFields] = useState([]);

	useEffect(() => {
		if (props?.data?.fields && props?.data?.fields.length > 0) {
			const fields = props?.data?.fields.map((field) => {
				return {
					...field,
					defaultValue: field.value || field.defaultValue,
				};
			});

			setFields(fields);
		}
	}, [props?.data?.fields]);

	const matchOptions = (value) => {
		if (!value) return [];
		const options = JSON.parse(value);

		return options.map((option) => {
			return { Id: option, Name: option };
		});
	};

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<div>Loading...</div>}>
			<div className="w-full h-100 pb-10 overflow-y-visible">
				<Form ref={myForm}>
					<input
						type="hidden"
						name="id"
						value={props?.isClone ? false : props.data.id || false}
					/>
					<input
						type="hidden"
						name="taskTemplateId"
						value={props?.data.taskTemplateId}
					/>

					<Each
						of={fields}
						render={(field, index) => {
							if (field?.isHidden) return null;
							return (
								<FormEntry
									label={field.name}
									required={field.isRequired}
									key={"form-entry-" + index}
									className={"sm:block hidden w-[90%]"}
								>
									<input
										type="hidden"
										name={`fields[${index}].fieldId`}
										value={field.id}
									/>
									<input
										type="hidden"
										name={`fields[${index}].inputType`}
										value={field.inputType}
									/>
									<InputField
										label={field.name}
										name={`fields[${index}].value`}
										inputType={field.inputType}
										defaultData={
											props.data.id
												? field.value
												: field.defaultValue
										}
										options={
											field?.values
												? JSON.parse(field?.values)
												: matchOptions(field?.options)
										}
										isRequired={field.isRequired}
										isDisabled={field.isDisabled}
										parentId={props?.data.taskGroupId}
									/>
								</FormEntry>
							);
						}}
					/>
				</Form>
			</div>
		</Suspense>
	);
});

export default TaskForm;
