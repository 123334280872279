import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
} from "react";
import { Loading } from "components/ui/Interactive";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { useAside, useTranslations, useCrud } from "hooks";
import { CheckBox, Input, MultipleSelect } from "components/ui/Input";
import { Link } from "react-router-dom";
import UserProfileService from "modules/persons/pages/Users/pages/Users/pages/UserProfiles/services";

const UrgencyForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const { asideBuilder } = useAside();

	const { getOne } = useCrud(props.service);

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);
	const [userProfileOptions, setUserProfileOptions] = useState([]);
	const [userProfiles, setUserProfiles] = useState([]);
	const [errorMessage, setErrorMessage] = useState(null);

	const userProfileService = new UserProfileService();

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}

		userProfileService.getOptionsList().then((res) => {
			setUserProfileOptions(res.data);
		});
	};

	useEffect(() => {
		initiateData();
	}, []);

	useEffect(() => {
		setErrorMessage(null);
	}, [userProfiles]);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			if (userProfiles.length === 0) {
				setErrorMessage(translate("pleaseSelectAtLeastOneUserProfile"));
				return false;
			} else {
				setErrorMessage(null);
			}
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	const closeAside = () => {
		asideBuilder.reset();
		asideBuilder.setOpen(false);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className="w-full h-100 pb-10 overflow-y-visible">
				<Form ref={myForm}>
					<input type="hidden" name="id" value={data?.id || false} />
					<FormEntry label="name" required={true}>
						<Input
							type="text"
							placeholder={"name"}
							required={true}
							name="name"
							defaultValue={data?.name}
						/>
					</FormEntry>
					<InternalFormDivider>
						{translate("configuration")}
					</InternalFormDivider>
					<FormEntry label="label" required={true}>
						<Input
							type="text"
							placeholder={"label"}
							required={true}
							name="label"
							defaultValue={data?.label}
						/>
					</FormEntry>
					<FormEntry label="priority" required={true}>
						<Input
							type="number"
							min={0}
							max={5}
							placeholder={"priority"}
							required={true}
							name="priority"
							defaultValue={data?.priority}
						/>
					</FormEntry>
					<FormEntry
						label="userProfiles"
						required={true}
						helpText={
							<div>
								<div className="text-red-600">
									{errorMessage}
								</div>
								<div align="right" className="pt-5 ">
									<Link
										onClick={() => closeAside()}
										className="text-sm"
										to="/persons/users/users#newUserProfile"
									>
										{" + "}
										{translate("addUserProfile")}
									</Link>
								</div>
							</div>
						}
					>
						<MultipleSelect
							name="userProfileIds"
							options={userProfileOptions}
							value={data?.profileIds}
							onSelectOption={(selected) =>
								setUserProfiles(selected)
							}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default UrgencyForm;
