import { CheckBox } from "components/ui/Input";
import { useState, useEffect } from "react";
import { Each } from "utils/Each";
import ColumnItem from "./ColumnItem";

export const ColumnsForm = ({ data, onColumnsChange = () => {} }) => {
	const [columns, setColumns] = useState(
		data?.columns?.map((column, i) => ({
			...column,
			hidden: column.hidden || false,
			order: i,
		})) || []
	);

	const moveUp = (index) => {
		if (index === 0) {
			return;
		}
		const item = columns[index];
		const newColumns = [...columns];
		newColumns.splice(index, 1);
		newColumns.splice(index - 1, 0, item);
		newColumns.forEach((item, index) => {
			item.order = index + 1;
		});
		updateColumns(newColumns);
	};

	const moveDown = (index) => {
		if (index === columns.length - 1) {
			return;
		}
		const item = columns[index];
		const newColumns = [...columns];
		newColumns.splice(index, 1);
		newColumns.splice(index + 1, 0, item);
		newColumns.forEach((item, index) => {
			item.order = index + 1;
		});
		updateColumns(newColumns);
	};

	const onCheckChange = (index, checked) => {
		const newColumns = [...columns];
		newColumns[index].hidden = !checked;
		updateColumns(newColumns);
	};

	const updateColumns = (columns) => {
		setColumns(columns);
		onColumnsChange(columns);
	};

	return (
		<div className="w-full h-100 pb-10 pl-5 overflow-y-visible">
			<div>
				<div className="border-b -mt-2">
					<CheckBox
						labelClass="text-lg font-bold"
						selected={columns.every((column) => !column.hidden)}
						halfChecked={
							columns.some((column) => !column.hidden) &&
							columns.some((column) => column.hidden)
						}
						label="displayAllColumns"
						onChange={(e) => {
							const newColumns = columns.map((column) => ({
								...column,
								hidden: !e.target.checked,
							}));
							updateColumns(newColumns);
						}}
					/>
				</div>
			</div>
			<div className="pt-1">
				<Each
					of={columns}
					render={(column, index) => {
						return (
							<ColumnItem
								column={column}
								index={index}
								onMoveUp={moveUp}
								onMoveDown={moveDown}
								onCheckChange={onCheckChange}
								moveUpDisabled={index === 0}
								moveDownDisabled={index === columns.length - 1}
							/>
						);
					}}
				/>
			</div>
		</div>
	);
};

export default ColumnsForm;
