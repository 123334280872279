import React from "react";
import { Navigate } from "react-router-dom";

const Login = React.lazy(() => import("./pages/Login"));
const Restore = React.lazy(() => import("./pages/Restore"));
const ExternalLogin = React.lazy(() => import("./pages/ExternalLogin"));
const ExternalIntegrationLogin = React.lazy(() =>
	import("./pages/ExternalIntegration")
);

const routes = [
	{ index: true, element: <Login /> },
	{ path: "reset-password", element: <Restore /> },
	{ path: "forgot-password", element: <Restore /> },
	{ path: "external-login", element: <ExternalLogin /> },
	{ path: "external-integration", element: <ExternalIntegrationLogin /> },
	{ path: "*", element: <h1>Not Found</h1> },
];

export const privateAccessibleRoutes = [
	{ path: "external-integration", element: <ExternalIntegrationLogin /> },
	{ path: "*", element: <Navigate to="/" replace /> },
];

export default routes;
