import { withDashboardLayout } from "../../hoc";
import { Outlet } from "react-router-dom";

const CoursesModule = () => {
	return <Outlet />;
};

const config = {
	page: "locations",
	pages: [
		{ id: "locations", label: "locations", to: "" },
		{
			id: "locations/external",
			label: "externalLocations",
			to: "external",
		},
		{ id: "locations/settings", label: "settings", to: "settings" },
	],
};

export default withDashboardLayout(CoursesModule, config);
