import UserSettings from "../pages/Settings";
import TeamsAndDepartments from "../pages/TeamsAndDepartments";
import Users from "../pages/Users";

const items = [
	{
		name: "iTransportUsers",
		path: "/persons/users/users",
		key: "users",
		element: <Users />,
	},
	{
		name: "teamsAndDepartments",
		path: "/persons/users/teams",
		key: "teamsAndDepartments",
		element: <TeamsAndDepartments />,
	},
	{
		name: "settings",
		key: "settings",
		path: "/persons/users/settings",
		element: <UserSettings />,
	},
];
export default items;
