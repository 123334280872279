import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
} from "react";
import { Loading } from "components/ui/Interactive";
import { Input, TextArea } from "components/ui/Input";
import { FormEntry, Form, InternalFormDivider } from "components/form";

import toast from "react-hot-toast";
import Permission from "../components/Permission";
import { useTranslations, useCrud } from "hooks";

const PermissionGroupForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);
	const { getOne } = useCrud(props.service);
	const [allPermissions, setAllPermissions] = useState([]);

	const fetchPermissions = async () => {
		const res = await props.service.getAllPermissions();
		if (res.error) {
			toast(translate("somethingWentWrong"), {
				duration: 2000,
				icon: "❗️",
			});
		} else {
			setAllPermissions(res.data);
		}
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	useEffect(() => {
		fetchPermissions();
	}, []);

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className="w-full h-100 pb-10 overflow-y-auto">
				<Form ref={myForm}>
					<Input type="hidden" name="id" value={data?.id || false} />
					<InternalFormDivider>
						{translate("generalInformation")}
					</InternalFormDivider>
					<FormEntry label={"groupName"}>
						<Input
							placeholder={"i.e. Task Creator"}
							type="text"
							required={true}
							name="name"
							defaultValue={data?.name || ""}
						/>
					</FormEntry>
					<FormEntry label={"description"}>
						<TextArea
							rows={4}
							placeholder={"i.e. Can create tasks"}
							required={false}
							name="description"
							defaultValue={data?.description || ""}
						/>
					</FormEntry>
					<InternalFormDivider>
						{translate("permissions")}
					</InternalFormDivider>
					<tr>
						<td colSpan={2}>
							{Object.keys(allPermissions).map(
								(allPermissionKey, allPermissionIndex) => {
									const currentPermissions =
										allPermissions[allPermissionKey];

									return (
										<div>
											<div className="flex items-center text-md text-center font-semibold text-cyan-700 uppercase pt-8 pb-4">
												<div className="flex-grow border-t border-cyan-500"></div>
												<span className="px-5 text-lg">
													{translate(
														allPermissionKey
													)}
												</span>
												<div className="flex-grow border-t border-cyan-500"></div>
											</div>
											<div>
												{Object.keys(
													currentPermissions
												).map((key, index) => (
													<Permission
														permissionKey={key}
														key={index}
														index={index}
														activePermissions={
															data?.permissions ||
															null
														}
														permissions={
															currentPermissions
														}
													/>
												))}
											</div>
										</div>
									);
								}
							)}
						</td>
					</tr>
				</Form>
			</div>
		</Suspense>
	);
});
export default PermissionGroupForm;
