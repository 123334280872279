import { useCallback, useContext } from "react";
import { PermissionsContext } from "contexts/PermissionsManager";

export const PERMISSION = {
	View: 1,
	Create: 2,
	Delete: 4,
	AssignToUser: 8,
	Sync: 8,
	Import: 8,
	Export: 16,
	Impersonate: 32,
	Allocate: 32,
};

/**
 * Resource FE to BE Mapping
 * In case of change in backend, we can just rename it here
 */

export const RESOURCE = {
	ActiveDirectory: "ActiveDirectory",
	Address: "Address",
	Asset: "Asset",
	CustomField: "CustomField",
	Dashboard: "Dashboard",
	DefaultSetting: "DefaultSetting",
	Department: "Department",
	Dispatcher: "Dispatcher",
	Documentations: "Documentations",
	HL7: "HL7",
	Language: "Language",
	Message: "Message",
	Notification: "Notification",
	Modules: "Modules",
	Patient: "Patient",
	Permission: "Permission",
	Profile: "Profile",
	Rule: "Rule",
	TaskGroup: "TaskGroup",
	TaskItem: "TaskItem",
	TaskType: "TaskType",
	Team: "Team",
	Template: "Template",
	TenantSettings: "TenantSettings",
	Urgency: "Urgency",
	User: "User",
	SuperAdmin: "SuperAdmin",
};

/**
 * Usage:
 * import usePermissions, {RESOURCE, PERMISSION} from "hools/usePermissions";
 * ...
 * const { hasPermission } = usePermissions();
 * if ( hasPermission(RESOURCE.User, PERMISSION.View) ) {
 *      Grant view data
 * }
 *
 */
const usePermissions = () => {
	const permissions = useContext(PermissionsContext);

	const hasPermission = useCallback(
		(resource, permission) => {
			// if (permission === 8)
			// 	console.log("Trying for permission", resource, permissions);
			if (!permissions || permission === null) return false;
			if (
				Object.keys(permissions).length > 0 &&
				permissions?.SuperAdmin !== null &&
				permissions?.SuperAdmin !== undefined
			) {
				return true;
			}

			if (permissions?.[resource] === undefined) return false;
			else {
				return (permissions[resource] & permission) === permission;
			}
		},
		[permissions]
	);

	return {
		hasPermission,
	};
};

export default usePermissions;
