import React, { useState, useEffect, useCallback } from "react";

import PropTypes from "prop-types";

import {
	buildPagination,
	buildFilterParams,
	buildExportParams,
	sanitizeFilters,
} from "utils/pagination";

import Table from "./table";
import Pagination from "./parts/Pagination";
import useDebounce from "hooks/useDebounce";

import TableColumns from "./ColumnPicker";

import { useTranslations } from "hooks";
const defaultConfig = {
	displayCheckBoxes: true,
	displayHeaders: true,
	isExportable: true,
	columnsCanBeModified: true,
	isFilterable: true,
};

const TableWrapper = ({
	data,
	meta,
	columns,
	controls = <></>,
	onRowClick = () => {},
	onTableRequestChange = () => {},
	onExportClick = async () => {},
	isLoading = false,
	tableTitle = "table",
	hasRowActions = false,
	rowActions,
	multipleRowActions,
	RowActionsComponent = null,
	MultipleRowActionsComponent = null,
	defaultFilters = null,
	...config
}) => {
	config = { ...defaultConfig, ...config };
	const controlsCount =
		0 +
		(config.isFilterable ? 1 : 0) +
		(config.isExportable ? 1 : 0) +
		(config.columnsCanBeModified ? 1 : 0);

	const { translate } = useTranslations();
	const [showFilters, setShowFilters] = useState(false);
	const [filters, setFilters] = useState({});
	const searchFilters = useDebounce(filters, 500);
	const [postFilters, setPostFilters] = useState({});
	const searchPostFilters = useDebounce(postFilters, 500);
	const [exportInProgress, setExportInProgress] = useState(false);
	const [displayColumns, setDisplayColumns] = useState(columns);
	const [tableIsOverflowed, setTableIsOverflowed] = useState(false);

	useEffect(() => {
		setDisplayColumns(columns);
	}, [columns]);

	const columnChangeHandler = (columns) => {
		columns.sort((a, b) => a.order - b.order);
		setDisplayColumns(columns);
	};

	const { openColumnsPicker } = TableColumns({
		columns: displayColumns,
		tableTitle: tableTitle,
		onColumnsChange: columnChangeHandler,
	});

	const _sendRequest = useCallback(
		(pgn = null, params = null, l_postFilters = null) => {
			if (pgn === null) pgn = { ...meta };
			if (params === null) params = searchFilters;
			if (l_postFilters === null) l_postFilters = searchPostFilters;

			if (defaultFilters) {
				Object.keys(defaultFilters).forEach((key) => {
					if (defaultFilters[key]) {
						params[key] = defaultFilters[key];
					}
				});
			}

			const toExcludeOnApply = columns
				.filter((item) => item.filter && item.filter?.isParam !== false)
				.filter((item) => item.filter && item.filter.toExcludeOnApply)
				.map((item) => item.filter.toExcludeOnApply)
				.flat();

			toExcludeOnApply.forEach((item) => {
				if (params[item.on]) {
					delete params[item.exclude];
				}
			});

			const paginationStr = buildPagination(pgn);
			const filterStr = buildFilterParams(params);

			onTableRequestChange(
				`${paginationStr}&${filterStr}`,
				sanitizeFilters(l_postFilters)
			);
		},
		[meta, searchFilters, onTableRequestChange]
	);

	const changePagination = (key, value) => {
		const newPagination = { ...meta, [key]: value };
		if (key === "size") newPagination["page"] = 1;
		_sendRequest(newPagination, null);
	};

	const sortChange = (sortKey, sortAsc) => {
		const newParams = { ...meta, sortBy: sortKey, sortAsc };
		_sendRequest(newParams, null);
	};

	useEffect(() => {
		_sendRequest(null, searchFilters);
	}, [searchFilters]);

	useEffect(() => {
		_sendRequest(null, searchFilters, searchPostFilters);
	}, [searchPostFilters]);

	useEffect(() => {
		_sendRequest(null, searchFilters);
	}, [defaultFilters]);

	const getExportData = () => {
		return buildExportParams(meta, searchFilters);
	};

	const headerVariants = {
		1: "grid grid-cols-1  gap-4",
		2: "grid grid-cols-2  gap-4",
		3: "grid grid-cols-3  gap-4",
	};

	const horizontalScrollTip = `
	<div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
	<div className="flex">
	  <div>
		<div className="font-bold">
		<i class="ri-information-line"></i>
		<b>To scroll horizontally:</b></div>
		<div className="text-sm">
		  <i>Keep the</i> <b>Shift</b> <i> button of <br/> your keyboard pressed</i> and <br/><u>Scroll the <b>mouse wheel<b/></u>
		</div>
	  </div>
	</div>
  </div>
	`;

	return (
		<div className={"flex flex-col"}>
			<div
				className={"flex sm:flex-col md:flex-col lg:flex-row flex-wrap"}
			>
				<div className="flex-1">{controls}</div>
				<div className="flex justify-end items-end">
					<div className="md:none flex flex-1"></div>
					{controlsCount > 0 && (
						<div className={`${headerVariants[controlsCount]}`}>
							{config.isFilterable && (
								<div
									onClick={() => setShowFilters((f) => !f)}
									className="flex flex-row justify-end"
								>
									<div className="cursor-pointer py-2 flex flex-row items-center opacity-75 hover:opacity-100">
										<i className="ri-filter-3-line"></i>
										<div className="ml-1 text-xs font-bold">
											{translate("filters")}
										</div>
									</div>
								</div>
							)}

							{config.columnsCanBeModified ? (
								<div
									onClick={() => openColumnsPicker()}
									className="flex flex-row justify-end"
								>
									<div
										data-tooltip-id={`table-tooltip`}
										data-tooltip-html={
											tableIsOverflowed
												? horizontalScrollTip
												: ""
										}
										data-tooltip-variant="info"
										data-tooltip-delay-show={1000}
										className="columns-picker-trigger-button cursor-pointer py-2 flex flex-row items-center justify-end opacity-75 hover:opacity-100"
									>
										<i className="ri-table-line"></i>
										<div className="ml-1 text-xs font-bold">
											{translate("columns")}
										</div>
									</div>
								</div>
							) : (
								<></>
							)}
							{config.isExportable ? (
								<div
									onClick={async () => {
										setExportInProgress(true);
										await onExportClick(getExportData());
										setExportInProgress(false);
									}}
									className="flex flex-row justify-end"
								>
									<div className="cursor-pointer py-2 flex flex-row items-center justify-end opacity-75 hover:opacity-100">
										{exportInProgress ? (
											<>
												<i className="ri-loader-2-line animate-spin"></i>
												<div className="ml-1 text-xs font-bold">
													{translate("progressing")}
													...
												</div>
											</>
										) : (
											<>
												<i className="ri-file-chart-line"></i>
												<div className="ml-1 text-xs font-bold">
													{translate("export")}
												</div>
												<i className="ml-2 ri-arrow-down-s-line"></i>
											</>
										)}
									</div>
								</div>
							) : (
								<></>
							)}
						</div>
					)}
				</div>
			</div>

			<>
				<Table
					isLoading={isLoading}
					columns={displayColumns}
					config={config}
					data={data}
					onRowClick={onRowClick}
					onSortChange={sortChange}
					sortBy={meta?.sortBy}
					sortAsc={meta?.sortAsc}
					showFilters={showFilters}
					hasRowActions={hasRowActions}
					rowActions={rowActions}
					RowActionsComponent={RowActionsComponent}
					MultipleRowActionsComponent={MultipleRowActionsComponent}
					multipleRowActions={multipleRowActions}
					onFilterChange={(key, value) => {
						setFilters((f) => {
							return { ...f, [key]: value };
						});
					}}
					onPostFilterChange={(key, value) => {
						setPostFilters((f) => {
							return { ...f, [key]: value };
						});
					}}
					searchFilters={filters}
					onOverflowChange={(overflowed) => {
						setTableIsOverflowed(overflowed);
					}}
				/>
				<div className="flex flex-row justify-end mt-5">
					<Pagination
						page={meta.page || 1}
						size={meta.size || 20}
						total={meta.total || 0}
						onPageChange={(newPage) =>
							changePagination("page", newPage)
						}
						onPageSizeChange={(newPageSize) =>
							changePagination("size", newPageSize)
						}
					/>
				</div>
			</>
		</div>
	);
};

TableWrapper.propTypes = {
	data: PropTypes.array,
	meta: PropTypes.shape({
		page: PropTypes.number,
		size: PropTypes.number,
		total: PropTypes.number,
		sortBy: PropTypes.string,
		sortAsc: PropTypes.bool,
	}),
	columns: PropTypes.array,
	config: PropTypes.shape({
		displayCheckBoxes: PropTypes.bool,
		displayHeaders: PropTypes.bool,
		isExportable: PropTypes.bool,
		columnsCanBeModified: PropTypes.bool,
	}),
};

export default TableWrapper;
