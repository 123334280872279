import api from "services/api";
import { extractMeta } from "utils/pagination";

class AbstractService {
	constructor() {
		if (new.target === AbstractService) {
			throw new TypeError("Cannot construct Abstract instances directly");
		}
		this.endpoint = null;
		this.api = api;
		this.queryString = "page=1&sortBy=Id&pageSize=200&sortAsc=true";
	}

	setEndpoint(endpoint) {
		this.endpoint = endpoint;
	}

	async mapData(data, decodeJSONString = false) {
		const { id, ...rest } = data;
		return {
			...this.formatPayload(rest, decodeJSONString),
		};
	}

	async beforeEach() {
		return true;
	}

	async getAll(queryString) {
		await this.beforeEach();
		const result = await api.get(`${this.endpoint}?${queryString}`);
		return {
			data: result.data,
			meta: extractMeta(result, queryString),
		};
	}

	async getOne(id) {
		await this.beforeEach();
		const result = await api.get(`${this.endpoint}/${id}`);
		return result;
	}

	async getMultiple(ids = []) {
		await this.beforeEach();
		const result = await api.get(
			`${this.endpoint}?ids=${ids.join("&ids=")}`
		);
		return result;
	}

	async create(data) {
		await this.beforeEach();
		return await api.post(`${this.endpoint}`, await this.mapData(data));
	}

	async update(id, data) {
		await this.beforeEach();
		return await api.put(
			`${this.endpoint}/${id}`,
			await this.mapData(data)
		);
	}

	async remove(id) {
		await this.beforeEach();
		return await api.delete(`${this.endpoint}/${id}`);
	}

	async getExport(queryString = null) {
		await this.beforeEach();
		return await api.get(
			`${this.endpoint}/export` + (queryString ? `?${queryString}` : ""),
			{ responseType: "blob" }
		);
	}

	async getOptionsList(queryString = null, allItemIsHidden = false) {
		const qs = (queryString || this.queryString)?.concat(
			`&HideAllItem=${allItemIsHidden}`
		);
		await this.beforeEach();
		const result = await api.get(`${this.endpoint}/options-list?${qs}`);
		return {
			data: result?.data?.map((d) => ({
				value: d?.id,
				label: d?.name,
			})),
			meta: extractMeta(result, qs),
		};
	}

	parseValue(value, decodeJSONString = false) {
		if (value === "null") return null;
		if (value === "undefined") return null;
		if (value === undefined) return null;
		if (value === "") return null;
		if (value === "true") return true;
		if (value === "false") return false;

		if (value.match(/^\d{2}:\d{2}:\d{2}$/)) return value;
		if (value.match(/^\d{2}:\d{2}$/)) return value + ":00";

		if (
			decodeJSONString &&
			typeof value === "string" &&
			(value.startsWith("[") || value.startsWith("{")) &&
			(value.endsWith("]") || value.endsWith("}"))
		)
			return JSON.parse(value);

		if (isNaN(value)) return value;
		if (!isNaN(value)) return parseFloat(value);
	}

	destructObjectSubmit(data, keyName) {
		return Object.keys(data).reduce((acc, key) => {
			const match = key.match(
				new RegExp(`${keyName}\\[(\\d+)\\]\\.(.+)`)
			);
			if (match) {
				const index = parseInt(match[1]);
				const fieldKey = match[2];
				acc[index] = { ...(acc[index] || {}), [fieldKey]: data[key] };
				delete data[key];
			}

			return acc;
		}, []);
	}

	destructObjectSubmit2D(data, keyName) {
		return Object.keys(data).reduce((acc, key) => {
			const match = key.match(
				new RegExp(`${keyName}\\[(\\d+)\\]\\[(\\d+)\\]\\.(.+)`)
			);
			if (match) {
				const index = parseInt(match[1]);
				const index2 = parseInt(match[2]);
				const fieldKey = match[3];
				acc[index] = acc[index] || [];
				acc[index][index2] = {
					...(acc[index][index2] || {}),
					[fieldKey]: data[key],
				};
				delete data[key];
			}

			return acc;
		}, []);
	}
	/**
	 * Use this method to format the data that are like something.somebody into something:{somebody: value}
	 * @param {*} data
	 */
	formatPayload(data, decodeJSONString = false) {
		const result = {};

		for (const key in data) {
			let isArray = key.endsWith("Array");

			const parts = key.split(".");
			let current = result;
			for (let i = 0; i < parts.length; i++) {
				let part = parts[i];
				if (isArray && i === parts.length - 1) {
					part = part.replace("Array", "");
				}
				if (i === parts.length - 1) {
					if (isArray) {
						current[part] = this.prepareArrayPayload(data[key]);
					} else {
						current[part] = this.parseValue(
							data[key],
							decodeJSONString
						);
					}
				} else {
					current[part] = current[part] || {};
					current = current[part];
				}
			}
		}

		return result;
	}

	prepareArrayPayload(data) {
		return Array.isArray(data)
			? data
			: typeof data === "string" && data?.length > 0
			? data?.split(",")?.map(Number)
			: [];
	}
}

export default AbstractService;
