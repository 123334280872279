import React, { useState, useEffect, forwardRef } from "react";
import Card from "components/ui/Card";
import { Table } from "components/ui/Table";
import TeamService from "../services";
import TeamColumns from "../models/index";
import { useTranslations, useCrud } from "hooks";
import { getToday } from "utils";

import { CrudOperations } from "hoc";

const Tables = ({ permissions, controls = {} }) => {
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [defaultFilters, setDefaultFilters] = useState({
		Date: getToday(),
		Statuses: undefined,
	});

	const service = new TeamService();
	const { getAll } = useCrud(service);

	const { translate } = useTranslations();

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			setIsLoading(true);
			const res = await getAll(l_queryString || queryString);
			setIsLoading(false);
			setData(res);
		} catch (error) {
			console.error("Error on Get All request: ", error);
		}
	};

	useEffect(() => {
		controlsUpdateHandler(controls);
	}, [controls]);

	const controlsUpdateHandler = (data) => {
		delete data.taskGroup;
		const keyMap = {
			date: "Date",
			statuses: "Statuses",
		};

		const newDefaultValues = {};

		Object.keys(data).forEach((key) => {
			newDefaultValues[keyMap[key]] = data[key];
		});

		setDefaultFilters(newDefaultValues);
	};

	return (
		<Card className="mt-2" collapsible header={"teams"} defaultOpen={true}>
			<Table
				defaultFilters={defaultFilters}
				displayCheckBoxes={false}
				isLoading={isLoading}
				isExportable={false}
				columns={TeamColumns}
				meta={data.meta}
				data={data.data}
				onTableRequestChange={(queryStr) => {
					setQueryString(queryStr);
					fetchAndLoad(queryStr);
				}}
			/>
		</Card>
	);
};
export default Tables;
