import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
} from "react";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { Input, Select, MultipleSelect, ReSelect } from "components/ui/Input";
import { runAction } from "modules/utils";
import { Loading } from "components/ui/Interactive";
import FormElement from "../components/FormElement";
import Strength from "../../../../../../../../auth/components/PasswordStrength/";
import { checkStrength } from "../../../../../../../../auth/services/passwordUtils";
import TeamInput from "../components/TeamInput";
import { useTranslations, useCrud } from "hooks";
import UserProfileService from "modules/persons/pages/Users/pages/Users/pages/UserProfiles/services";
import UserSettings from "./user.settings";
import { useAside } from "hooks";
import toast from "react-hot-toast";
import PasswordValidator from "modules/auth/components/PasswordValidator";

const getYearsAgoDate = (years) => {
	const dateObject = new Date(
		new Date().getFullYear() - years,
		new Date().getMonth(),
		new Date().getDate()
	);
	const date = `${dateObject.getFullYear()}-${String(
		dateObject.getMonth() + 1
	).padStart(2, "0")}-${String(dateObject.getDate()).padStart(2, "0")}`;
	return date;
};

const UserForm = forwardRef((props, ref) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const myForm = useRef(null);
	const passwordInput = useRef(null);
	const [userProfileOptions, setUserProfileOptions] = useState([]);

	const [password, setPassword] = useState("");
	const [passwordIsValid, setPasswordIsValid] = useState(false);
	const [passwordHelperIsOpen, setPasswordHelperIsOpen] = useState(false);

	const [userProfiles, setUserProfiles] = useState([]);
	const [settings, setSettings] = useState(null);
	const [userProfileErrors, setUserProfileOptionsErrors] = useState([]);
	const [genderOptions, setGenderOptions] = useState([]);
	const { translate } = useTranslations();
	const { getOne } = useCrud(props.service);
	const userProfileService = new UserProfileService();
	const { helperAsideBuilder, asideBuilder } = useAside();
	const [helperActive, setHelperActive] = useState(false);

	//TODO: Get Specific fields from BE
	const specificFields = [
		{
			name: "dateOfBirth",
			label: "dateOfBirth",
			type: "date",
			otherOptions: {
				max: getYearsAgoDate(17),
				min: getYearsAgoDate(100),
			},
		},
		{
			name: "gender",
			label: "gender",
			type: "select",
			options: genderOptions.map((item) => ({
				value: item.id,
				label: item.name,
			})),
		},
	];

	//Get general information fields from BE
	const generalInformationFields = [
		{
			name: "phoneNumber",
			label: "phoneNumber",
			type: "tel",
			placeholder: "(555) 555-1234",
		},
	];

	const getTenantSettings = async (action = () => {}) => {
		await runAction("tenants", "getSettings")
			.then((res) => {
				action(res);
				setSettings(res);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const openDialog = () => {
		helperAsideBuilder.setTitle(translate("accountSettings"));
		helperAsideBuilder.setComponent(UserSettings);
		helperAsideBuilder.setOpen(true);
		helperAsideBuilder.setSaveCallback(saveSettings);
		helperAsideBuilder.hideSaveButton(false);
		helperAsideBuilder.setComponentProps({
			data: data,
			service: props.service,
			requestReload: () => {
				props?.requestReload();
				initiateData();
			},
			helperIsOpen: (status) => {
				asideBuilder.hideSaveButton(status);
				setHelperActive(status);
			},
			formSuccess: () => {
				asideBuilder.setOpen(false);
				asideBuilder.reset();
			},
		});
		helperAsideBuilder.build();
	};

	const saveSettings = async (settingsData) => {
		await _doSave(settingsData).then((res) => {
			props?.requestReload();
			initiateData();
		});
	};

	const _doSave = async (payload) => {
		try {
			const res = await props.service.updateSettings(data.id, payload);
			if (res?.succeeded) {
				toast(translate("settingsUpdatesSuccessfully"), {
					duration: 2000,
					icon: "✅",
				});
				return res;
			} else {
				toast(
					translate(res?.message || translate("somethingWentWrong")),
					{
						duration: 2000,
						icon: "❗️",
					}
				);
				throw new Error(
					res?.message || translate("somethingWentWrong")
				);
			}
		} catch (error) {
			toast(translate(error.message) || translate("somethingWentWrong"), {
				duration: 2000,
				icon: "❗️",
			});
			console.error("Error on Update request: ", error);
			throw error;
		}
	};

	const getData = () => {
		setUserProfileOptionsErrors([]);

		if (myForm.current && myForm.current.checkValidity()) {
			//if it is user create
			if (!data?.id) {
				//check for password strength

				if (!passwordIsValid) {
					passwordInput.current.focus();
					passwordInput.current.setCustomValidity(
						translate("passwordDoesNotMeetRequirements")
					);
					return false;
				}
			}

			if (userProfiles.length === 0) {
				setUserProfileOptionsErrors([
					translate("pleaseSelectAtLeastOneUserProfile"),
				]);
				return false;
			}

			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	const initiateData = () => {
		if (props?.data?.id) {
			getOne(props?.data?.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			getTenantSettings();
			setLoading(false);
		}

		userProfileService.getOptionsList().then((res) => {
			setUserProfileOptions(res.data);
		});

		runAction("generalContents", "getGenderOptions").then((res) => {
			setGenderOptions(res);
		});
	};

	useEffect(() => {
		initiateData();
	}, []);

	const TriggerButton = forwardRef((props, ref) => {
		return (
			<button
				ref={ref}
				type="button"
				className="mr-3 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center absolute top-4 right-3"
				onClick={props.onClick}
			>
				<i className={`ri-settings-4-line text-lg`}></i>
				<span className="pl-1">{translate("settings")}</span>
			</button>
		);
	});
	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={loading} />}>
			{!isNaN(data?.id) && <TriggerButton onClick={() => openDialog()} />}

			<div
				className={`w-full h-100 pb-10 overflow-y-visible ${
					helperActive ? "opacity-40" : ""
				}`}
			>
				<Form ref={myForm}>
					<input type="hidden" name="id" value={data?.id || false} />
					<input
						type="hidden"
						name="userProfiles"
						value={JSON.stringify(userProfiles)}
					/>
					<input
						type="hidden"
						name="userName"
						value={data?.userName || false}
					/>
					<input
						type="hidden"
						name="settings.loginTwoFaPolicy"
						value={
							data?.settings?.loginTwoFaPolicy ??
							settings?.loginTwoFaPolicy ??
							0
						}
					/>
					<input
						type="hidden"
						name="settings.changePasswordNextLogin"
						value={
							data?.settings?.changePasswordNextLogin ??
							settings?.changePasswordNextLogin ??
							false
						}
					/>
					<input
						type="hidden"
						name="settings.passwordExpiresInDays"
						value={
							data?.settings?.passwordExpiresInDays ??
							settings?.passwordExpiresInDays ??
							0
						}
					/>
					<input
						type="hidden"
						name="settings.language"
						value={
							data?.settings?.language.toUpperCase() ??
							settings?.language.toUpperCase() ??
							"EN"
						}
					/>

					{isNaN(data?.id) && (
						<>
							<InternalFormDivider>
								{translate("authentication")}
							</InternalFormDivider>

							<FormEntry label="username" required>
								<Input
									type="text"
									placeholder="johndoe"
									name="userName"
									required={true}
									defaultValue={data?.userName || ""}
								/>
							</FormEntry>
							<FormEntry
								label={"password"}
								required
								helpText={
									<PasswordValidator
										password={password}
										onChange={(isValid) => {
											setPasswordHelperIsOpen(!isValid);
											setPasswordIsValid(isValid);
										}}
										isOpen={passwordHelperIsOpen}
									/>
								}
							>
								<Input
									ref={passwordInput}
									type="password"
									placeholder="password"
									name="password"
									required={true}
									minLength={7}
									regexExpression={
										"(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@$!%*#?~(&)+=^_-]).{7,}"
									}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
							</FormEntry>
						</>
					)}
					<InternalFormDivider>
						{translate("generalInformation")}
					</InternalFormDivider>
					<FormEntry label={"firstName"} required>
						<Input
							disabled={helperActive}
							type="text"
							placeholder="John"
							name="name"
							minLength={3}
							required={true}
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label={"lastName"} required>
						<Input
							disabled={helperActive}
							type="text"
							placeholder="Doe"
							name="lastName"
							required={true}
							defaultValue={data?.lastName}
						/>
					</FormEntry>
					<FormEntry
						label={"email"}
						required={data?.id ? false : true}
					>
						<Input
							type="email"
							placeholder="info@example.com"
							name="email"
							required={true}
							disabled={data?.id ? true : false}
							regexExpression={
								"^(?!.{50})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
							} //We are checking this in order to match the backend validation
							defaultValue={data?.email}
						/>
					</FormEntry>

					{generalInformationFields.map((field) => {
						return (
							<FormElement
								{...field}
								defaultValue={data?.[field.name] || null}
							/>
						);
					})}

					<InternalFormDivider>
						{translate("itransportInformation")}
					</InternalFormDivider>

					<FormEntry
						label="userProfiles"
						required
						helpText={
							<div className="pb-1">
								{userProfileErrors.map((item) => {
									return (
										<p className="text-xs text-red-500">
											{item}
										</p>
									);
								})}
							</div>
						}
					>
						<MultipleSelect
							hasAllOption={false}
							noAllAtSubmit={true}
							disabled={helperActive}
							options={userProfileOptions}
							name="profileIds"
							value={data?.profileIds}
							onSelectOption={(options) => {
								//we need this options for defaultProfileId
								const l_userProfiles = options.map((item) => ({
									value: item,
									label: userProfileOptions.find(
										(option) => option.value === item
									)?.label,
								}));
								setUserProfiles(l_userProfiles);
							}}
						/>
					</FormEntry>
					<FormEntry label="defaultProfile" required>
						<ReSelect
							required={true}
							disabled={helperActive}
							name={"DefaultProfileId"}
							defaultValue={
								userProfiles.find(
									(item) =>
										item.value === data?.defaultProfileId
								)?.value ?? userProfiles.at(0)?.value
							}
							options={userProfiles}
						/>
					</FormEntry>
					<TeamInput
						disabled={helperActive}
						selectedValue={data?.teams}
					/>

					{specificFields.length > 0 && (
						<InternalFormDivider>
							{translate("specificFields")}
						</InternalFormDivider>
					)}
					{specificFields.map((field) => {
						return (
							<FormElement
								{...field}
								defaultValue={data?.[field.name] || null}
								disabled={helperActive}
							/>
						);
					})}
				</Form>
			</div>
		</Suspense>
	);
});
export default UserForm;
