import React, {
	forwardRef,
	useRef,
	useContext,
	useState,
	useEffect,
} from "react";
import { PortalContext } from "contexts/PortalManager";
import { runAction } from "modules/utils";
import { useTranslations } from "hooks";

const TruncatedString = (value, truncateAfter = null) => {
	if (!truncateAfter) return value;

	let truncatedString = value;
	if (value?.length > truncateAfter) {
		truncatedString = value.substring(0, truncateAfter) + "...";
	}
	return (
		<div
			data-tooltip-variant="info"
			data-tooltip-content={value?.length > truncateAfter ? value : ""}
			data-tooltip-id={`table-tooltip`}
		>
			{truncatedString}
		</div>
	);
};

const AddressSelector = forwardRef(
	(
		{
			address = null,
			defaultValue = null,
			name = "",
			error = "",
			onSelect = () => {},
			required = false,
			disabled = false,
			placeholder = "selectAddress",
			initiallySelectDisabled = false,
			hideLabel = false,
			label = null,
			labelClassName = null,
			onChange = () => {},
			containerClassName = "",
			truncateAt = null,
		},
		ref
	) => {
		const { callAction } = useContext(PortalContext);
		const { translate } = useTranslations();
		const [errorMessage, setErrorMessage] = useState(error);
		const [selectedAddress, setSelectedAddress] = useState(address);
		const [inputInvalid, setInputInvalid] = useState(false);
		const inputRef = useRef();

		useEffect(() => {
			setErrorMessage(error);
		}, [error]);

		useEffect(() => {
			setSelectedAddress(convertKeysToLowercase(address || defaultValue));
		}, [address, defaultValue]);

		const checkValidity = () => {
			setInputInvalid(
				inputRef.current && inputRef.current.validity.valid === false
			);
		};

		useEffect(() => {
			checkValidity();
		}, [selectedAddress]);

		const selectAddress = (address) => {
			setSelectedAddress(address);
			onSelect(address);
			setErrorMessage(null);
			onChange(getInputAddress(getAddress(address)));
		};

		function convertKeysToLowercase(obj) {
			const newObj = {};
			if (!obj) return obj;
			Object.keys(obj).forEach((key) => {
				let newKey = key;
				if (key[0] === key[0].toUpperCase()) {
					newKey = key[0].toLowerCase() + key.slice(1);
				}
				if (typeof obj[key] === "object" && obj[key] !== null) {
					newObj[newKey] = convertKeysToLowercase(obj[key]);
				} else {
					newObj[newKey] = obj[key];
				}
			});
			return newObj;
		}

		function getAddressString(address) {
			if (address.child) {
				return `${address.name} > ${getAddressString(address.child)}`;
			} else {
				return address.name;
			}
		}

		const getAddress = (address) => {
			if (address) {
				if (address.child) {
					return getAddress(address.child);
				} else {
					return address;
				}
			}
			return null;
		};

		const getInputAddress = (address) => {
			return { id: address.id, level: address.level };
		};

		return (
			<>
				{!hideLabel && label && (
					<label
						className={`block mb-1 text-xs font-medium text-gray-700 ${labelClassName}`}
					>
						{translate(label || "", true)}
					</label>
				)}
				<div
					ref={ref}
					tabIndex="0"
					onClick={() => {
						!disabled &&
							runAction("addresses", "openAddressSelect", {
								callAction,
								data: {
									selected: selectedAddress,
									initiallySelectDisabled,
								},
								onSelect: (s) => selectAddress(s.formatted),
							});
					}}
					className={`group relative  bg-gray-50 border ${
						inputInvalid ? "border-red-500" : " border-gray-300"
					} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 my-1 flex flex-row space-between ${
						disabled
							? "opacity-50 cursor-not-allowed"
							: "cursor-pointer"
					} ${containerClassName} `}
				>
					{
						<input
							ref={inputRef}
							type="text"
							name={name}
							required={required}
							className="sr-only pt-2 pl-10"
							value={
								selectedAddress
									? JSON.stringify(
											getInputAddress(
												getAddress(selectedAddress)
											)
									  )
									: ""
							}
							onChange={checkValidity}
							onBlur={checkValidity}
						/>
					}
					{selectedAddress ? (
						<div className="flex-1 text-black">
							{TruncatedString(
								getAddressString(selectedAddress),
								truncateAt
							)}
						</div>
					) : (
						<div className="flex-1 text-slate-400">
							{translate(placeholder)}
						</div>
					)}
					{selectedAddress && !disabled && (
						<div
							className="pr-1 relative  cursor-pointer opacity-40 group-hover:opacity-100"
							onClick={(e) => {
								e.stopPropagation();
								setSelectedAddress(null);
								onSelect(null);
								onChange(null);
							}}
						>
							<i className=" pr-1 ri-close-circle-fill"></i>
						</div>
					)}

					<i className="ri-hotel-line"></i>
				</div>
				{errorMessage && (
					<div className="text-red-500 text-sm">{errorMessage}</div>
				)}
			</>
		);
	}
);
export default AddressSelector;
