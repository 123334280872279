import NotImplemented from "components/system/NotImplemented";

import PriorityRule from "../pages/PriorityRule/parts/PriorityRuleTable";
import AdjustStaRule from "../pages/AdjustStaRule/parts/AdjustStaRuleTable";
import GroupingTaskRule from "../pages/GroupingTaskRule/parts/GroupingTaskRuleTable";
import ChangeTaskTypeRule from "../pages/ChangeTaskTypeRule/parts/ChangeTaskTypeRuleTable";
import MonitorDoubleTaskRule from "../pages/MonitorDoubleTaskRule/parts/MonitorDoubleTaskRuleTable";
import Overview from "../pages/Overview/Overview";
const routes = [
	{
		header: "general",
		key: "General",
		items: [
			// {
			// 	name: "overview",
			// 	key: "Overview",
			// 	hash: "#overview",
			// 	component: Overview,
			// },
			{
				name: "priorityRule",
				key: "PriorityRule",
				hash: "#priorityRule",
				component: PriorityRule,
			},
			{
				name: "adjustStaRule",
				key: "AdjustStaRule",
				hash: "#adjustStaRule",
				component: AdjustStaRule,
			},
			{
				name: "groupingTaskRule",
				key: "GroupingTaskRule",
				hash: "#groupingTaskRule",
				component: GroupingTaskRule,
			},
			{
				name: "changeTaskTypeRule",
				key: "ChangeTaskTypeRule",
				hash: "#changeTaskTypeRule",
				component: ChangeTaskTypeRule,
			},
			{
				name: "monitorDoubleTaskRule",
				key: "MonitorDoubleTaskRule",
				hash: "#monitorDoubleTaskRule",
				component: MonitorDoubleTaskRule,
			},
		],
	},
];
export default routes;
