import React, {
	useRef,
	useImperativeHandle,
	forwardRef,
	useState,
} from "react";

import { useTranslations } from "hooks";
import Tooltip from "components/ui/Input/internal/Tooltip";

const Input = forwardRef((props, ref) => {
	const { translate } = useTranslations();

	let inputRef = useRef(null);
	if (ref) {
		inputRef = ref;
	}

	return (
		<Tooltip text={translate(props?.title ?? null, true)}>
			<div className={`relative w-full ${props.containerClassName}`}>
				{!props.hideLabel && props.label && (
					<label
						className={`block mb-1 text-xs font-medium text-gray-700 ${props.labelClassName}`}
					>
						{translate(props?.label || "", true)}
					</label>
				)}
				<div className="flex flex-row items-center">
					<input
						ref={inputRef}
						{...props}
						placeholder={translate(props?.placeholder || "", true)}
						className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 my-1 ${props.className}`}
					/>
					{props.type === "password" && ( // Render the button only if type is password
						<button
							type="button"
							className="absolute right-1 transform -translate-y-1/2 text-gray-500 p-2.5"
							style={{ top: "calc(50% + 9px)" }}
							onClick={() => {
								// Toggle between text and password type
								const input = inputRef?.current || {};
								input.type =
									input.type === "password"
										? "text"
										: "password";
							}}
						>
							<i
								className={
									inputRef?.current?.type === "password"
										? "ri-eye-off-line"
										: "ri-eye-line"
								}
							></i>
						</button>
					)}

					{props.sideLabel && (
						<span className="ml-2 text-sm text-gray-500">
							{translate(props?.sideLabel || "", true)}
						</span>
					)}
				</div>
			</div>
		</Tooltip>
	);
});

Input.defaultProps = {
	className: "",
	sideLabel: null,
	hideLabel: false,
};

export default Input;
