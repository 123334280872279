import React from "react";

const Pagination = ({ page, size, total, onPageChange, onPageSizeChange }) => {
	const totalPages = Math.ceil(total / size);

	const handlePageChange = (newPage) => {
		if (newPage >= 1 && newPage <= totalPages && newPage !== page) {
			onPageChange(newPage);
		}
	};

	const handlePageSizeChange = (newSize) => {
		if (newSize !== size) {
			onPageSizeChange(newSize);
		}
	};

	const handlePrevPage = () => {
		if (page > 1) {
			onPageChange(page - 1);
		}
	};

	const handleNextPage = () => {
		if (page < totalPages) {
			onPageChange(page + 1);
		}
	};

	const renderPageButtons = () => {
		const buttons = [];

		// Show the current page and a few pages around it
		buttons.push(
			<button
				key={"prev"}
				className={`${
					page === 1
						? "cursor-not-allowed opacity-25"
						: "cursor-pointer hover:opacity-50"
				} px-3 py-1 mx-1 text-center `}
				onClick={() => handlePrevPage()}
				disabled={page === 1}
			>
				<i className="ri-arrow-left-s-line"></i>
			</button>
		);

		for (
			let i = Math.max(1, page - 2);
			i <= Math.min(page + 2, totalPages);
			i++
		) {
			buttons.push(
				<button
					key={i}
					className={`cursor-pointer px-3 py-1 mx-1 text-center hover:opacity-50 ${
						page === i ? "text-primary-500" : ""
					}`}
					onClick={() => handlePageChange(i)}
				>
					{i}
				</button>
			);
		}

		// Show ellipsis if there are more pages
		if (page + 2 < totalPages) {
			buttons.push(
				<span key="ellipsis" className="mx-1">
					...
				</span>
			);
		}

		// Show the last page
		if (page < totalPages - 2) {
			buttons.push(
				<button
					key={totalPages}
					className={`cursor-pointer px-3 py-1 mx-1 text-center hover:opacity-50 ${
						page === totalPages ? "text-primary-500" : ""
					}`}
					onClick={() => handlePageChange(totalPages)}
				>
					{totalPages}
				</button>
			);
		}

		buttons.push(
			<button
				key={"next"}
				className={`${
					page === totalPages
						? "cursor-not-allowed opacity-25"
						: "cursor-pointer hover:opacity-50"
				} px-3 py-1 mx-1 text-center `}
				onClick={() => handleNextPage()}
				disabled={page === totalPages}
			>
				<i className="ri-arrow-right-s-line"></i>
			</button>
		);

		return buttons;
	};

	return (
		<div className="flex items-center justify-between p-4">
			<div className="flex items-center border-r pr-4">
				<select
					className="px-2 py-1 text-right cursor-pointer rounded outline-slate-200 hover:outline"
					value={size}
					onChange={(e) =>
						handlePageSizeChange(parseInt(e.target.value))
					}
				>
					<option className="text-right" value={5}>
						5
					</option>
					<option className="text-right" value={10}>
						10
					</option>
					<option className="text-right" value={20}>
						20
					</option>
					<option className="text-right" value={50}>
						50
					</option>
					<option className="text-right" value={100}>
						100
					</option>
				</select>
			</div>

			<div className="flex items-center pl-2">{renderPageButtons()}</div>
		</div>
	);
};

export default Pagination;
