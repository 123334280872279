import React, { useState, useEffect, useRef } from "react";
import Card from "components/ui/Card";
import TenantUtils from "./utils";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { useTranslations } from "hooks";
import MultiDateInput from "./components/MultiDateInput";

import { useCrud } from "hooks";
import {
	CheckBox,
	Input,
	TextArea,
	Select,
	ReSelect,
	Button,
} from "components/ui/Input";
import TenantService from "./services/index";

const TenantSettingsView = ({ permissions }) => {
	const [settings, setSettings] = useState(null);

	const myForm = useRef(null);
	const { translate, getLanguages } = useTranslations();
	const service = new TenantService();
	const { create } = useCrud(service);
	const [isLoading, setIsLoading] = useState(false);
	const [languages, setLanguages] = useState([]);

	const fetchLanguages = async () => {
		const l_languages = await getLanguages();

		const langOptions = l_languages.map((lang) => {
			return { label: lang.name, value: lang.languageCode };
		});

		setLanguages(langOptions);
	};

	//handle the form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(myForm.current);
		const formDataObject = Object.fromEntries(formData.entries());
		setIsLoading(true);
		await postSettings(formDataObject, (res) => {
			setIsLoading(false);
			getSettings(false);
		});
	};

	const postSettings = async (data, onSuccess = () => {}) => {
		if (data) {
			return await create(data, {
				creationSuccessMessage: "tenantSettingsUpdatedSuccessfully",
			}).then((res) => {
				onSuccess(res);
			});
		}
		return null;
	};

	useEffect(() => {
		getSettings();
		fetchLanguages();
	}, []);

	const getSettings = async () => {
		setIsLoading(true);
		const l_settings = await TenantUtils.getSettings(false);
		setIsLoading(false);
		setSettings(l_settings);
	};

	const twoFaPolicyOptions = [
		{ value: 1, label: "off" },
		{ value: 2, label: "optional" },
		{ value: 3, label: "mandatory" },
	];

	return (
		<Card
			isLoading={isLoading}
			className="mt-2"
			collapsible
			header={"tenantSettings"}
			defaultOpen={true}
		>
			<div>
				<Form ref={myForm} onSubmit={(e) => handleSubmit(e)}>
					<InternalFormDivider>
						{translate("generalSettings")}
					</InternalFormDivider>

					<FormEntry required label={"language"}>
						<ReSelect
							required
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							name="language"
							options={languages}
							defaultValue={
								settings?.language.toUpperCase() || null
							}
						/>
					</FormEntry>

					<FormEntry required label={"twoFactorSiteTitle"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3 "
							placeholder={"twoFactorSiteTitle"}
							required={true}
							name="twoFactorSiteTitle"
							defaultValue={settings?.twoFactorSiteTitle || ""}
						/>
					</FormEntry>
					<FormEntry label={"nationalHolidays"}>
						<MultiDateInput
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3 "
							name="nationalHolidays"
							defaultValue={settings?.nationalHolidays}
						/>
					</FormEntry>

					<InternalFormDivider>
						{translate("dateTimeSettings")}
					</InternalFormDivider>
					<FormEntry required label={"dateFormat"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							placeholder={"dateFormat"}
							required={true}
							name="dateFormat"
							defaultValue={settings?.dateFormat || ""}
						/>
					</FormEntry>
					<FormEntry required label={"timeFormat"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							placeholder={"timeFormat"}
							required={true}
							name="timeFormat"
							defaultValue={settings?.timeFormat || ""}
						/>
					</FormEntry>
					<FormEntry required label={"timezone"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							placeholder={"timezone"}
							required={true}
							name="timezone"
							defaultValue={settings?.timezone || ""}
						/>
					</FormEntry>
					<InternalFormDivider>
						{translate("configuration")}
					</InternalFormDivider>

					<FormEntry required label={"passwordExpiresInDays"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							placeholder={"passwordExpiresInDays"}
							type="number"
							min={1}
							required={true}
							name="passwordExpiresInDays"
							defaultValue={settings?.passwordExpiresInDays || ""}
						/>
					</FormEntry>

					<FormEntry label={"changePasswordNextLogin"}>
						<CheckBox
							name="changePasswordNextLogin"
							label="changePasswordNextLogin"
							selected={
								settings?.changePasswordNextLogin || false
							}
						/>
					</FormEntry>

					<FormEntry label={"encryptPatientName"}>
						<CheckBox
							name="encryptPatientName"
							label="encryptPatientName"
							selected={settings?.encryptPatientName || false}
						/>
					</FormEntry>

					<FormEntry required label={"loginTwoFaPolicy"}>
						<ReSelect
							required
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							name="loginTwoFaPolicy"
							options={twoFaPolicyOptions}
							defaultValue={settings?.loginTwoFaPolicy || null}
						/>
					</FormEntry>

					{permissions.Update && (
						<tr>
							<td colSpan={2}>
								<div className="text-right p-10 pb-5">
									<Button type="submit" isLoading={isLoading}>
										<i className="ri-save-line mr-2"></i>
										{translate("saveChanges")}
									</Button>
								</div>
							</td>
						</tr>
					)}
				</Form>
			</div>
		</Card>
	);
};

export default TenantSettingsView;
