import {
	forwardRef,
	Suspense,
	useRef,
	useImperativeHandle,
	useEffect,
	useState,
} from "react";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { Toggle, Input } from "components/ui/Input";
import { runAction } from "modules/utils";
import { useTranslations } from "hooks";
import { DayToggle } from "../components";
import DispatcherItem from "../components/DispatcherItem";
import AllocationItem from "../components/AllocationItem";

const TeamConfiguration = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const myForm = useRef(null);

	const [teamScheduleDays, setTeamScheduleDays] = useState([]);
	const [dispatcherItem, setDispatcherItem] = useState([]);
	const [breakOptions, setBreakOptions] = useState(
		props?.data.breakTimeOptions || []
	);
	const [teamSchedulesLoading, setTeamSchedulesLoading] = useState(true);
	const [dispatcherItemsLoading, setDispatcherItemsLoading] = useState(true);

	/*****************************************************************
	 ********************** FORM HANDLERS ****************************
	 *****************************************************************/

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
	};

	useImperativeHandle(ref, () => ({
		getData,
		clear: resetData,
	}));

	/*****************************************************************
	 ************************* EFFECTS *******************************
	 *****************************************************************/

	useEffect(() => {
		getViewPatientsLevelEnum();
		getDispatcherItem();
	}, [props?.data]);

	useEffect(() => {
		if (props?.helperIsOpen) {
			props?.helperIsOpen(props?.componentOpen || false);
		}
	}, [props.componentOpen]);

	/*****************************************************************
	 ************************* FETCHES *******************************
	 *****************************************************************/

	/**
	 * @returns {Promise<*>}
	 * @name getDispatcherItem
	 * @description Get the dispatcher items
	 */
	const getDispatcherItem = async () => {
		setDispatcherItemsLoading(true);
		const dispatcherItems = await props.service.getDispatcherItems();

		const dispatchers = dispatcherItems?.data?.map((item) => {
			const dispatcherItem = props?.data?.dispatcherItems?.find(
				(dispatcher) => dispatcher.itemId === item.itemId
			);
			return {
				order: dispatcherItem?.order || 0,
				value: dispatcherItem?.value || 0,
				enabled: dispatcherItem?.enabled || false,
				itemId: item.itemId,
				name: item.name,
				hasValue: item.hasValue,
			};
		});

		dispatchers.sort((a, b) => a.order - b.order);

		setDispatcherItem(dispatchers);
		setDispatcherItemsLoading(false);
		return dispatcherItems;
	};

	/**
	 * @name getViewPatientsLevelEnum
	 * @description Get the enum values for the team schedule days
	 * @returns {Promise<*>}
	 */
	const getViewPatientsLevelEnum = async () => {
		setTeamSchedulesLoading(true);
		const enumValues = await runAction(
			"tenants",
			"getEnum",
			"TeamScheduleDay"
		);
		const teamSchedules = [];
		Object.keys(enumValues).forEach((key) => {
			teamSchedules.push({
				day: key,
				value: enumValues[key],
			});
		});

		const schedules = teamSchedules.map((item) => {
			const schedule = props?.data?.timeScheduleItems?.find(
				(schedule) => schedule.scheduleDay === item.value
			);

			const scheduleEndTime =
				!schedule?.enabled && schedule?.end === "00:00:00"
					? "23:59:00"
					: schedule?.end;
			return {
				day: item.day,
				scheduleDay: item.value,
				startTime: schedule?.start || null,
				endTime: scheduleEndTime || null,
				enabled: schedule?.enabled || false,
			};
		});

		setTeamScheduleDays(schedules);
		setTeamSchedulesLoading(false);
		return teamSchedules;
	};

	/*****************************************************************
	 * ********************** HELPER FUNCTIONS ***********************
	 * *****************************************************************/

	/**
	 * @name moveUp
	 * @description Move the dispatcher item up
	 * @param {number} index
	 * @returns {void}
	 * */
	const moveUp = (index) => {
		if (index === 0) {
			return;
		}
		const item = dispatcherItem[index];
		const newDispatcherItem = [...dispatcherItem];
		newDispatcherItem.splice(index, 1);
		newDispatcherItem.splice(index - 1, 0, item);
		newDispatcherItem.forEach((item, index) => {
			item.order = index + 1;
		});
		setDispatcherItem(newDispatcherItem);
	};

	/**
	 * @name moveDown
	 * @description Move the dispatcher item down
	 * @param {number} index
	 * @returns {void}
	 * */
	const moveDown = (index) => {
		if (index === dispatcherItem.length - 1) {
			return;
		}
		const item = dispatcherItem[index];
		const newDispatcherItem = [...dispatcherItem];
		newDispatcherItem.splice(index, 1);
		newDispatcherItem.splice(index + 1, 0, item);
		newDispatcherItem.forEach((item, index) => {
			item.order = index + 1;
		});
		setDispatcherItem(newDispatcherItem);
	};

	/**
	 * Adds a new Break option input with default 1 minute
	 */
	const addBreakOption = () => {
		setBreakOptions((bo) => [...bo, 1]);
	};

	/**
	 * Removes a break option by index
	 * @param {*} index
	 */
	const removeBreakOption = (index) => {
		setBreakOptions((bo) => [
			...bo.slice(0, index),
			...bo.slice(index + 1),
		]);
	};

	/**
	 * Updates an option
	 * @param {*} value
	 * @param {*} index
	 */
	const handleBreakOptionChange = (value, index) => {
		const nbo = [...breakOptions];
		nbo[index] = value;
		console.log("setting new val", value);
		console.log(nbo);
		setBreakOptions(nbo);
	};

	return (
		<Suspense>
			<div className="w-full h-100 pb-10 overflow-y-visible">
				<Form ref={myForm}>
					<input
						type="hidden"
						name="id"
						value={props?.data?.id || false}
					/>
					<InternalFormDivider className="mb-0">
						{translate("dispatch")}
					</InternalFormDivider>
					<FormEntry
						label={"teamCanReceiveTasks"}
						longLabel={true}
						inputClass="flex justify-end pr-5"
					>
						<Toggle
							name={`canReceiveTasks`}
							defaultChecked={
								props?.data?.canReceiveTasks || true
							}
						/>
					</FormEntry>
					<InternalFormDivider className="pt-5">
						{translate("timeSchedule")}
					</InternalFormDivider>
					{teamSchedulesLoading ? (
						<tr>
							<td colSpan={2}>
								<div className="flex justify-center items-center">
									<i className="ri-loader-2-line animate-spin"></i>
								</div>
							</td>
						</tr>
					) : (
						<tr>
							<td colSpan={2}>
								{teamScheduleDays?.map((day, index) => (
									<DayToggle
										index={index}
										scheduleDay={day.scheduleDay}
										key={day.value}
										startTime={day.startTime}
										endTime={day.endTime}
										isChecked={day.enabled}
										day={day.day}
									/>
								))}
							</td>
						</tr>
					)}
					<InternalFormDivider className="pt-5">
						{translate("dispatchOrder")}
					</InternalFormDivider>
					{dispatcherItemsLoading ? (
						<tr>
							<td colSpan={2}>
								<div className="flex justify-center items-center">
									<i className="ri-loader-2-line animate-spin"></i>
								</div>
							</td>
						</tr>
					) : (
						<tr>
							<td colSpan={2}>
								{dispatcherItem.map((item, index) => (
									<DispatcherItem
										disabled={props?.data.parentId > 0}
										key={index}
										item={item}
										index={index}
										moveUpDisabled={index === 0}
										moveDownDisabled={
											index === dispatcherItem.length - 1
										}
										onMoveUp={moveUp}
										onMoveDown={moveDown}
										onToggleChange={(value) => {
											setDispatcherItem((prev) => {
												const newItems = [...prev];
												newItems[index].enabled = value;
												return newItems;
											});
										}}
									/>
								))}
							</td>
						</tr>
					)}

					<InternalFormDivider className="pt-5">
						{translate("taskAllocations")}
					</InternalFormDivider>
					<AllocationItem
						name={"keepAllocatedTasksDuringBreaks"}
						checked={
							props?.data?.keepAllocatedTasksDuringBreaks || false
						}
					/>
					<AllocationItem
						name={"keepAllocatedTasksDuringOffline"}
						checked={
							props?.data?.keepAllocatedTasksDuringOffline ||
							false
						}
					/>
					<InternalFormDivider className="pt-5">
						{translate("configureBreaks")}
					</InternalFormDivider>
					<FormEntry
						label={"Break Options"}
						longLabel={true}
						helpText={
							<div className="mt-2 flex justify-end mr-2">
								<button
									disabled={false}
									type="button"
									className="text-right text-black text-sm font-bold leading-normal"
									onClick={addBreakOption}
								>
									<i className="ri-add-line"></i>

									{translate("addOption")}
								</button>
							</div>
						}
					>
						<input
							type="hidden"
							name="breakTimeOptions"
							value={JSON.stringify(breakOptions)}
						/>
						{breakOptions.map((opt, index) => {
							return (
								<tr key={`break-option-${index}`}>
									<td className="w-full">
										<Input
											type="number"
											value={opt}
											sideLabel="min"
											min={1}
											onChange={(e) =>
												handleBreakOptionChange(
													e.target.value,
													index
												)
											}
										/>
									</td>
									<td>
										<button
											type="button"
											className="px-1"
											onClick={() =>
												removeBreakOption(index)
											}
										>
											<i className="ri-delete-bin-line"></i>
										</button>
									</td>
								</tr>
							);
						})}

						{breakOptions.length === 0 && (
							<tr>
								<td className="pt-2">--</td>
							</tr>
						)}
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default TeamConfiguration;
