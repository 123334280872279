export const getDefaultValue = (defaultValue, isMultiple) => {
	if (defaultValue) {
		try {
			let parsedValue = JSON.parse(defaultValue);
			if (Array.isArray(parsedValue)) {
				if (isMultiple) {
					return parsedValue;
				}
				if (parsedValue.length === 1) {
					return parsedValue[0];
				}
				return parsedValue;
			}

			return parsedValue;
		} catch (e) {
			return defaultValue;
		}
	}
	return null;
};

export const convertOptions = (options) => {
	return options.map((option) => {
		return {
			value: option.Id || option.id,
			label: option.Name || option.name,
		};
	});
};
