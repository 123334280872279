import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
	useContext,
} from "react";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import {
	ColorPicker,
	Input,
	MultipleSelect,
	Select,
	Radio,
	AddressSelector,
	ReSelect,
	CheckBox,
} from "components/ui/Input";

import { useTranslations, useCrud } from "hooks";
import { Loading } from "components/ui/Interactive";
import DepartmentService from "modules/persons/pages/Users/pages/TeamsAndDepartments/Departments/services";
import TaskTypeService from "modules/tasks/pages/Manage/pages/TaskTypes/services";
import TaskGroupService from "modules/tasks/pages/Manage/services";
import { useAside } from "hooks";
import TeamConfiguration from "./team.configuration";
import toast from "react-hot-toast";
import Department from "../components/Department";
import DuplicateTeamButton from "../components/DuplicateTeamButton";

const TeamForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const { getOne, create, update } = useCrud(props.service);
	const { helperAsideBuilder, asideBuilder } = useAside();
	const departmentService = new DepartmentService();
	const taskTypeService = new TaskTypeService();
	const taskGroupService = new TaskGroupService();

	const myForm = useRef(null);

	const [data, setData] = useState([]);
	const [otherTeams, setOtherTeams] = useState([]);

	const [loading, setLoading] = useState(true);
	const [taskTypesOptions, setTaskTypesOptions] = useState([]);
	const [taskGroupOptions, setTaskGroupOptions] = useState([]);
	const [departmentOptions, setDepartmentOptions] = useState([]);
	const [departmentIsvalid, setDepartmentIsValid] = useState(true);
	const [dispatchingMethod, setDispatchingMethod] = useState(1);
	const [helperActive, setHelperActive] = useState(false);
	const [initialParentId, setInitialParentId] = useState(null);
	const [takeOverTeamOptions, setTakeOverTeamOptions] = useState([]);

	const fetchTaskTypes = (taskGroups = []) => {
		let taskGroupIds = [];
		if (taskGroups && taskGroups.some((item) => item === 1)) {
			const options = taskGroupOptions?.map((item) => item.value) ?? [];
			taskGroupIds = options.filter((item) => item !== 1);
		} else {
			taskGroupIds = taskGroups?.map((item) => item) ?? [];
		}

		taskTypeService.getMultiParentOptionsList(taskGroupIds).then((res) => {
			setTaskTypesOptions(res);
		});
	};

	const getParentData = (id = null) => {
		if (id && id !== -1) {
			getOne(id).then((res) => {
				setData((prev) => {
					return {
						...prev,
						parentId: id,
						dispatchingMethod: res?.dispatchingMethod,
						numberOfTaskShown: res?.numberOfTaskShown,
						dispatcherItems: res?.dispatcherItems,
						departments: res?.departments,
					};
				});
				setDispatchingMethod(res?.dispatchingMethod);
				setDepartmentOptions(
					res?.departments.map(({ department }) => ({
						value: department.id,
						label: department.name,
					}))
				);
			});
		} else {
			if (props?.data.id) {
				getOne(props?.data.id).then((res) => {
					setData((prev) => {
						return {
							...prev,
							dispatchingMethod: res?.dispatchingMethod,
							numberOfTaskShown: res?.numberOfTaskShown,
							dispatcherItems: res?.dispatcherItems,
							departments: res?.departments,
							parentId: null,
						};
					});
					setDispatchingMethod(res?.dispatchingMethod);
				});
			} else {
				setData((prev) => {
					return {
						...prev,
						dispatchingMethod: null,
						numberOfTaskShown: null,
						dispatcherItems: null,
						parentId: null,
						departments: [],
					};
				});
				setDispatchingMethod(1);
			}

			fetchAssignDepartmentOptions();
		}
	};

	const getTakeOverTeams = (id) => {
		props.service.getTakeOverTeams(id).then((res) => {
			if (res?.data) setTakeOverTeamOptions(res.data);
			else setTakeOverTeamOptions([]);
		});
	};

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const runSubmit = () => {
		const formData = getData();
		if (!formData || !departmentIsvalid) return;
		saveAndLoad(formData);
	};

	const saveAndLoad = async (data) => {
		asideBuilder.setIsLoading(true);
		if (data) {
			try {
				if (isNaN(data.id)) {
					await create(data, {
						creationSuccessMessage: "teamCreatedSuccessfully",
					}).then((res) => {
						asideBuilder.setIsLoading(false);
						initiateData(res.data.id);
						openDialog(res.data);
					});
				} else {
					await update(data.id, data, {
						updateSuccessMessage: "teamUpdatedSuccessfully",
					}).then((res) => {
						asideBuilder.setIsLoading(false);
						asideBuilder.setOpen(false);
						asideBuilder.reset();
					});
				}
				props.requestReload();
			} catch (e) {
				asideBuilder.setIsLoading(false);
			}
		}
	};

	const resetData = () => {
		myForm.current.reset();

		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		runSubmit: () => runSubmit(),
		clear: () => resetData(),
	}));

	const initiateData = (id) => {
		if (id) {
			getOne(id).then((res) => {
				setData(res);
				getTakeOverTeams(id);
				setLoading(false);

				getParentData(res?.parentId);
				setDispatchingMethod(res?.dispatchingMethod || 1);
				fetchTaskTypes(res?.taskGroupIds || []);
				setInitialParentId(res?.parentId);
			});
		} else {
			setLoading(false);
			fetchAssignDepartmentOptions();
		}

		props.service.getOthers(id || null).then((res) => {
			setOtherTeams(res);
		});

		taskGroupService.getOptionsList().then((res) => {
			setTaskGroupOptions(res.data);
		});
	};

	const fetchAssignDepartmentOptions = async () => {
		departmentService.getOptionsList().then((res) => {
			setDepartmentOptions(res?.data.filter((item) => item.value !== 1));
		});
	};

	useEffect(() => {
		initiateData(props?.data.id);
	}, []);

	const openDialog = (l_data = null) => {
		helperAsideBuilder.setTitle(translate("teamConfiguration"));
		helperAsideBuilder.setComponent(TeamConfiguration);
		helperAsideBuilder.setOpen(true);
		helperAsideBuilder.setSaveCallback(saveConfiguration);
		helperAsideBuilder.hideSaveButton(false);
		helperAsideBuilder.setComponentProps({
			data: l_data || data,
			service: props.service,
			requestReload: () => {
				props?.requestReload();
				initiateData(props?.data.id);
			},
			helperIsOpen: (status) => {
				asideBuilder.hideSaveButton(status);
				setHelperActive(status);
			},
		});
		helperAsideBuilder.build();
	};

	const saveConfiguration = async (configData) => {
		props?.service.saveConfig(configData).then((res) => {
			if (res?.succeeded) {
				toast(translate("teamConfigurationSaved"), {
					duration: 2000,
					icon: "✅",
				});
				if (props?.requestReload) props?.requestReload();
				initiateData(data.id);
				asideBuilder.setOpen(false);
				asideBuilder.reset();

				return res;
			} else {
				toast(translate(res?.message || "somethingWentWrong"), {
					duration: 2000,
					icon: "❗️",
				});
			}
		});
	};

	const TriggerButton = forwardRef((props, ref) => {
		return (
			<button
				ref={ref}
				type="button"
				className="mr-3 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center absolute top-4 right-3"
				onClick={props.onClick}
			>
				<i className={`ri-settings-4-line text-lg`}></i>
				<span className="pl-1">{translate("configure")}</span>
			</button>
		);
	});

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<div>Loading...</div>}>
			{data?.id && initialParentId === data?.parentId && (
				<TriggerButton onClick={() => openDialog()} />
			)}

			<div
				className={`w-full h-100 pb-10 overflow-y-visible ${
					helperActive ? "opacity-40" : ""
				}`}
			>
				<Form ref={myForm}>
					<InternalFormDivider>
						{translate("generalInformation")}
					</InternalFormDivider>

					<input type="hidden" name="id" value={data?.id || false} />
					<FormEntry label="teamName" required>
						<Input
							disabled={helperActive}
							type="text"
							name="name"
							defaultValue={data?.name || ""}
							required={true}
							placeholder={"i.e. Patient Transport"}
						/>
					</FormEntry>
					<FormEntry label="parentTeam">
						<ReSelect
							disabled={helperActive}
							name="parentId"
							options={[
								{ value: -1, label: "<>" },
								...otherTeams.map((item) => ({
									value: item.id,
									label: item.name,
								})),
							]}
							defaultValue={data?.parentId || -1}
							onChange={(value) => {
								getParentData(value);
							}}
						/>
					</FormEntry>
					<FormEntry label="description">
						<Input
							disabled={helperActive}
							type="text"
							name="description"
							defaultValue={data?.description || ""}
							required={false}
						/>
					</FormEntry>
					<FormEntry required label="homeLocation">
						<AddressSelector
							disabled={helperActive}
							address={data?.homeLocation}
							name="homeLocation"
							required={true}
						/>
					</FormEntry>
					<FormEntry label="allowedDepartmentWay" required={true}>
						<Department
							departments={data?.departments}
							options={departmentOptions}
							disabled={helperActive}
							isValid={(status) => {
								setDepartmentIsValid(status);
							}}
						/>
					</FormEntry>
					<FormEntry label="color">
						<ColorPicker
							disabled={helperActive}
							name="color"
							value={data?.color}
						/>
					</FormEntry>

					<InternalFormDivider className="pt-10">
						{translate("tasks")}
					</InternalFormDivider>
					<FormEntry label="taskGroups">
						<MultipleSelect
							disabled={helperActive}
							name="taskGroupIds"
							options={taskGroupOptions}
							value={data.taskGroupIds}
							onSelectOption={(selected) =>
								fetchTaskTypes(selected)
							}
						/>
					</FormEntry>
					<FormEntry label="excludedTaskTypes">
						<MultipleSelect
							noAllAtSubmit={true}
							hasAllOption={false}
							disabled={helperActive}
							name="excludedTaskTypeIds"
							options={taskTypesOptions}
							defaultValue={data?.excludedTaskTypeIds}
						/>
					</FormEntry>
					<FormEntry label="takeOverTeams">
						<MultipleSelect
							noAllAtSubmit={true}
							hasAllOption={false}
							disabled={helperActive}
							name="fallBackTeamIds"
							options={takeOverTeamOptions}
							valueIsObject={true}
							defaultValue={data?.fallBackTeams}
						/>
					</FormEntry>
					<FormEntry
						label="dispatchingMethods"
						required={!data?.parentId}
					>
						<Radio
							title={
								data?.parentId &&
								"thisValueIsInheritedFromParent"
							}
							disabled={helperActive || data?.parentId > 0}
							name={"dispatchingMethod"}
							options={[
								{ label: "automaticDispatch", value: 1 },
								{ label: "manualDispatch", value: 2 },
								{ label: "listOfTasks", value: 3 },
							]}
							defaultSelected={data?.dispatchingMethod}
							required={true}
							onChange={(e) => {
								setDispatchingMethod(parseInt(e.target.value));
							}}
							alignment="vertical"
						/>
					</FormEntry>
					{dispatchingMethod === 3 ? (
						<FormEntry
							required={!data?.parentId}
							label={"nrOfTasksShown"}
						>
							<Input
								title={
									data?.parentId &&
									"thisValueIsInheritedFromParent"
								}
								disabled={helperActive || data?.parentId > 0}
								type="number"
								name="numberOfTaskShown"
								defaultValue={data?.numberOfTaskShown || ""}
								required={true}
								min={2}
								placeholder={"i.e. 2"}
							/>
						</FormEntry>
					) : (
						<input
							disabled={helperActive || data?.parentId > 0}
							type="hidden"
							name="numberOfTaskShown"
							value={1}
						/>
					)}
				</Form>
			</div>
			{data?.id && (
				<div className=" left-16 bottom-6 absolute">
					<DuplicateTeamButton {...props} />
				</div>
			)}
		</Suspense>
	);
});

export default TeamForm;
