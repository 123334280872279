import React, { useState, useEffect, forwardRef } from "react";
import Card from "components/ui/Card";
import { Table } from "components/ui/Table";
import EmployeeService from "../services";
import OnlineEmployeesColumns from "../models/index";
import { useTranslations, useCrud } from "hooks";

import { CrudOperations } from "hoc";

const Tables = ({ permissions }) => {
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const service = new EmployeeService();
	const { getAll } = useCrud(service);

	const { translate } = useTranslations();

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			setIsLoading(true);
			const res = await getAll(l_queryString || queryString);
			setIsLoading(false);
			setData(res);
		} catch (error) {
			console.error("Error on Get All request: ", error);
		}
	};

	return (
		<Card
			className="mt-2"
			collapsible
			header={"onlineEmployees"}
			defaultOpen={true}
		>
			<Table
				displayCheckBoxes={false}
				isLoading={isLoading}
				isExportable={false}
				columns={OnlineEmployeesColumns}
				meta={data.meta}
				data={data.data}
				onTableRequestChange={(queryStr) => {
					setQueryString(queryStr);
					fetchAndLoad(queryStr);
				}}
			/>
		</Card>
	);
};
export default Tables;
